import { DEFAULT_LOCALE } from '~/lib/constants'

export default function serializePrice(
  locale: string = DEFAULT_LOCALE,
  currencyCode: string,
  amount: number,
): string {
  if (!locale || !currencyCode || !amount) return null

  return (
    new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      useGrouping: true,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
      .format(amount)
      // Remove unbreakable space
      .replace(/\u00a0/g, '')
  )
}
