import classnames from 'classnames/bind'
import React, { useEffect, useRef, useState, forwardRef } from 'react'

import { useMeasure } from '@unlikelystudio/react-hooks'
import Slider from '@unlikelystudio/react-slider'

import RichText, { RichTextBlock } from '~/components/RichText'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface BannerProps {
  className?: string
  displayed: boolean
  text?: RichTextBlock[]
  innerRef?: any
}

function Banner({ text, innerRef }: BannerProps) {
  const [autoScrollText, setAutoScrollText] = useState(false)
  const textRef = useRef()
  const { width: textWidth } = useMeasure(textRef)
  const { width: bannerWidth } = useMeasure(innerRef)
  const textStyle = useStyle({ textPreset: 'text-10-12', color: 'white' })

  useEffect(() => {
    contentAutoPlayVerification()
  }, [bannerWidth, textWidth])

  const contentAutoPlayVerification = () => {
    const shouldAutoPlay = textWidth >= bannerWidth
    if (shouldAutoPlay !== autoScrollText) {
      setAutoScrollText((prevState) => !prevState)
    }
  }

  const Text = () => (
    <RichText ref={textRef} render={text} className={cx(textStyle, css.text)} />
  )

  return text ? (
    <section ref={innerRef} className={css.Banner}>
      <div className={cx(css.shadow, css.shadowLeft)}></div>
      {autoScrollText && (
        <Slider infinite autoPlay={{ speed: -1.2 }}>
          <Text />
          <Text />
        </Slider>
      )}
      {!autoScrollText && <Text />}
      <div className={cx(css.shadow, css.shadowRight)}></div>
    </section>
  ) : null
}

Banner.defaultProps = {}

export default forwardRef<any, BannerProps>((props, ref) => (
  <Banner innerRef={ref} {...props} />
))
