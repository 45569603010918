import { SortBy } from '~/providers/FiltersProvider'

import { reduceObjectToQuantity } from '~/utils/reduce-object-to-quantity'

import { FiltersState } from './index'
import { FiltersReducerAction } from './reducer'

export const addFilter = (
  state: FiltersState,
  action: FiltersReducerAction,
) => {
  const { facet, value } = action.payload
  const currentState = state.filters?.[facet] ?? []

  return {
    ...state,
    filters: {
      ...state.filters,
      [facet]: [...currentState, value],
    },
  }
}

export const addFilters = (
  state: FiltersState,
  action: FiltersReducerAction,
) => {
  const { filters } = action.payload

  return {
    ...state,
    filters: {
      ...state.filters,
      ...filters,
    },
  }
}

export const removeFilter = (
  state: FiltersState,
  action: FiltersReducerAction,
) => {
  const { facet, value } = action.payload

  const filters = {
    ...state.filters,
    [facet]: state.filters[facet].filter((val) => val !== value),
  }

  const filtersAmount = reduceObjectToQuantity(filters)

  return {
    ...state,
    ...(filtersAmount === 0
      ? { sortBy: SortBy.DEFAULT, filters: {} }
      : { filters }),
  }
}

export const removeAll = (state: FiltersState) => {
  return {
    ...state,
    sortBy: SortBy.DEFAULT,
    filters: {},
  }
}

export const sortFilter = (
  state: FiltersState,
  action: FiltersReducerAction,
) => {
  return {
    ...state,
    sortBy: action.payload.value,
  }
}

export const setDefaultFilters = (
  state: FiltersState,
  action: FiltersReducerAction,
) => {
  return {
    ...state,
    defaultFilters: action.payload.defaultFilters,
  }
}

export const setFacets = (
  state: FiltersState,
  action: FiltersReducerAction,
) => {
  return {
    ...state,
    facets: action.payload.facets,
  }
}
