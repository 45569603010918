import { Attribute } from '@unlikelystudio/ecommerce-typed/types/shopify'

export function customAttributesAsObject(customAttributes: Attribute[]): {
  [key: string]: string
} {
  return customAttributes
    .map(({ key, value }) => ({
      [key]: value,
    }))
    .reduce((acc, current) => {
      return {
        ...acc,
        ...current,
      }
    }, {})
}
