import {
  Metafield,
  MetafieldInput,
} from '@unlikelystudio/ecommerce-typed/types/shopify-admin'

export const allowedMetafields = [
  {
    namespace: 'customer',
    key: 'birthday',
  },
  {
    namespace: 'customer',
    key: 'gender',
  },
]

const bypassableNamespaces = ['customer_address']

export default function formatMetafields(metafields) {
  const formattedMetafields = metafields?.map((metafield) => ({
    namespace: metafield.namespace,
    key: metafield.key,
  }))

  return formattedMetafields
    ?.filter(
      (formattedMetafield) =>
        !allowedMetafields.some(
          (allowedMetafield) =>
            `${allowedMetafield.key}${allowedMetafield.namespace}` ==
            `${formattedMetafield.key}${formattedMetafield.namespace}`,
        ),
    )
    .filter(
      (formattedMetafield) =>
        !bypassableNamespaces.includes(formattedMetafield.namespace),
    )
}

export interface KeyValuePair {
  [key: string]: string
}

export function fromMetafieldsToKeyValuePairs(metafields) {
  return metafields?.edges?.reduce((currentKeyValuePairs, { node }) => {
    const key = `${node?.namespace}_${node?.key}`
    return {
      [key]: node?.value,
      ...currentKeyValuePairs,
    }
  }, {})
}

export function fromKeyValuePairsToMetafields(
  keyValuePair: KeyValuePair,
  initialMetafields: Metafield[],
): MetafieldInput[] {
  if (!keyValuePair) return null

  return Object.keys(keyValuePair)?.map((inputKey) => {
    const splittedKey = inputKey.split('_', 1)

    const namespace = splittedKey?.[0]
    const key = inputKey.slice(namespace.length + 1)

    const id = initialMetafields.find((metafield) => {
      return metafield?.namespace === namespace && metafield?.key === key
    })?.id

    return {
      ...(id ? { id } : {}),
      key,
      namespace,
      value: keyValuePair?.[inputKey],
      type: 'single_line_text_field',
    }
  })
}

interface AddressKeyValuePair {
  [key: string]: {
    value: string
    key: string
  }
}

export function fromKeyValuePairsToAddressMetafields(
  keyValuePair: AddressKeyValuePair,
  initialMetafields: Metafield[],
): MetafieldInput[] {
  return Object.keys(keyValuePair).map((namespace) => {
    const id = initialMetafields.find(
      (metafield) =>
        metafield?.namespace === namespace &&
        metafield?.key === keyValuePair?.[namespace]?.key,
    )?.id

    return {
      ...(id ? { id } : {}),
      namespace,
      key: keyValuePair?.[namespace]?.key,
      value: keyValuePair?.[namespace]?.value,
      type: 'single_line_text_field',
    }
  })
}
