import React, { createContext, useContext, ReactNode } from 'react'

import { LinkProps } from '~/components/Link'

export const BreadcrumbContext = createContext<BreadcrumbContent>({})

export function useBreadCrumb() {
  return useContext(BreadcrumbContext)
}

export interface BreadcrumbContent {
  links?: LinkProps[]
}

export interface BreadcrumbProviderProps extends BreadcrumbContent {
  children?: ReactNode | ReactNode[]
}

export default function BreadCrumbProvider({
  children,
  links,
}: BreadcrumbProviderProps) {
  return (
    <BreadcrumbContext.Provider value={{ links }}>
      {children}
    </BreadcrumbContext.Provider>
  )
}
