import classnames from 'classnames/bind'
import React from 'react'

import Slider, { useSliderState } from '@unlikelystudio/react-slider'

import Image, { ImageProps } from '~/components/Image'
import InlineCta, { InlineCtaProps } from '~/components/InlineCta'
import RichText, { RichTextBlock } from '~/components/RichText'
import SliderControls from '~/components/SliderControls'
import WishlistManager from '~/components/WishlistManager'

import { useStyle } from '~/providers/StyleProvider'

import chunk from '~/utils/chunk'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export enum ReassuranceActionTypes {
  WISHLIST = 'wishlist',
  GIFT = 'gift',
}
export interface ReassuranceItemProps {
  type?: ReassuranceActionTypes.WISHLIST | ReassuranceActionTypes.GIFT
  title?: RichTextBlock[]
  text?: RichTextBlock[]
  image?: ImageProps
  link?: InlineCtaProps
}

export interface ReassuranceProps {
  className?: string
  id?: string
  ids?: string[]
  prismicId?: string
  prismicIds?: string[]
  items?: ReassuranceItemProps[]
  withSlider?: boolean
  withSeparator?: boolean
  onClick?: (string) => void
}

const DEFAULT_ALT = 'Reassurance item icon'

function Reassurance({
  className,
  id,
  ids,
  prismicId,
  prismicIds,
  items,
  withSeparator,
  withSlider,
  onClick,
}: ReassuranceProps) {
  const [{ slideIndex, setSlideIndex }, setSliderState] = useSliderState()
  const gridStyle = useStyle({ grid: 'base-grid' })
  const titleStyle = useStyle({ textPreset: 'title-10' })
  const textStyle = useStyle({
    textPreset: 'rich-text-variant-1',
    color: 'gray-47',
  })

  const processed = chunk(items, 3)

  const renderItem = (
    { image, title, text, link, type },
    index,
  ): ReassuranceItemProps => {
    const isWishlist = type === ReassuranceActionTypes.WISHLIST
    const ctaProps = {
      className: css.cta,
      ...link,
    }

    return (
      <div
        key={`item${index}`}
        className={cx(css.item, { withSlider, withSeparator })}>
        <div className={css.content}>
          {image && (
            <Image
              className={css.image}
              layout="fixed"
              sizesFromBreakpoints={[{ columns: 1.5 }]}
              {...image}
              alt={image?.alt ?? DEFAULT_ALT}
            />
          )}
          {title && (
            <RichText className={cx(titleStyle, css.title)} render={title} />
          )}
          {text && (
            <RichText className={cx(textStyle, css.text)} render={text} />
          )}
          {link && !isWishlist && (
            <InlineCta
              {...ctaProps}
              onClick={(e) => {
                if (type) e.preventDefault()
                onClick && onClick(type)
              }}
            />
          )}
          {link && isWishlist && (
            <WishlistManager
              id={prismicId}
              ids={prismicIds}
              shopifyId={id}
              shopifyIds={ids}>
              {({ handleClick }) => (
                <InlineCta {...ctaProps} link={null} onClick={handleClick} />
              )}
            </WishlistManager>
          )}
        </div>
      </div>
    )
  }

  return (
    <section className={cx(css.Reassurance, className)}>
      <div className={cx(gridStyle, css.grid, { withSlider })}>
        {processed?.map((items, id) => {
          return (
            <div key={`group${id}`} className={cx(css.gridContent)}>
              {items?.map(renderItem)}
            </div>
          )
        })}
      </div>
      {withSlider && (
        <>
          <Slider
            className={css.slider}
            onSlideIndexChange={(index) => setSlideIndex(index)}
            setSliderState={setSliderState}
            infinite
            snap>
            {items?.map(renderItem)}
          </Slider>
          <SliderControls
            length={items?.length}
            className={css.sliderControls}
            slideIndex={slideIndex}
            setSlideIndex={setSlideIndex}
          />
        </>
      )}
    </section>
  )
}

Reassurance.defaultProps = {}

export default Reassurance
