import classnames from 'classnames/bind'
import React from 'react'

import Link, { LinkProps } from '~/components/Link'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface FooterColumnProps {
  className?: string
  titleClassName?: string
  title?: string
  links?: LinkProps[]
}

function FooterColumn({
  className,
  titleClassName,
  title,
  links,
}: FooterColumnProps) {
  const titleStyle = useStyle({ textPreset: 'title-10' })
  const linkStyle = useStyle({ textPreset: 'cta-14-12', color: 'gray-47' })

  return (
    <div className={cx(className, css.FooterColumn)}>
      <h3 className={cx(titleStyle, titleClassName)}>{title}</h3>
      {links?.map((link, index: number) => (
        <Link key={index} className={cx(linkStyle, css.link)} {...link} />
      ))}
    </div>
  )
}

FooterColumn.defaultProps = {}

export default FooterColumn
