import { useCallback, useState } from 'react'

export interface UseUnderlineParams {
  onMouseEnter?: (e) => void
  onMouseLeave?: (e) => void
  forceUnderline?: boolean
}
export interface UseUnderlineReturnType {
  onMouseEnter: (e) => void
  onMouseLeave: (e) => void
  shouldUnderline: boolean
}

function useUnderline({
  onMouseEnter,
  onMouseLeave,
  forceUnderline,
}: UseUnderlineParams): UseUnderlineReturnType {
  const [shouldUnderline, setShouldUnderline] = useState<boolean>(null)

  const underlineOnMouseEnter = useCallback(
    (e) => {
      onMouseEnter && onMouseEnter(e)
      setShouldUnderline(true)
    },
    [onMouseEnter],
  )

  const underlineOnMouseLeave = useCallback(
    (e) => {
      onMouseLeave && onMouseLeave(e)
      setShouldUnderline(false)
    },
    [onMouseLeave],
  )

  return {
    onMouseEnter: underlineOnMouseEnter,
    onMouseLeave: underlineOnMouseLeave,
    shouldUnderline: forceUnderline || shouldUnderline,
  }
}

export default useUnderline
