import classnames from 'classnames/bind'
import React from 'react'

import { ReactComponent as Account } from './account.svg'
import { ReactComponent as ArrowSelect } from './arrow-select.svg'
import { ReactComponent as Arrow } from './arrow.svg'
import { ReactComponent as Bag } from './bag.svg'
import { ReactComponent as Chevron } from './chevron.svg'
import { ReactComponent as Close } from './close.svg'
import { ReactComponent as Heart } from './heart.svg'
import { ReactComponent as Minus } from './minus.svg'
import { ReactComponent as Plus } from './plus.svg'
import { ReactComponent as Search } from './search.svg'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface IconProps {
  className?: string
  onClick?: any
  light?: boolean
  active?: boolean
}

export function icon(Icon, style) {
  const Component = ({ className, onClick, light, active }: IconProps) => (
    <Icon
      className={cx(className, style, { light, active })}
      onClick={onClick}
    />
  )
  Component.defaultProps = {
    light: false,
  }
  return Component
}

export const SearchIcon = icon(Search, css.SearchIcon)
export const CloseIcon = icon(Close, css.CloseIcon)
export const AccountIcon = icon(Account, css.AccountIcon)
export const ArrowIcon = icon(Arrow, css.ArrowIcon)
export const ArrowSelectIcon = icon(ArrowSelect, css.ArrowSelectIcon)
export const HeartIcon = icon(Heart, css.HeartIcon)
export const BagIcon = icon(Bag, css.BagIcon)
export const PlusIcon = icon(Plus, css.PlusIcon)
export const MinusIcon = icon(Minus, css.MinusIcon)
export const ChevronIcon = icon(Chevron, css.ChevronIcon)
