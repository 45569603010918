import { RefObject, useCallback } from 'react'

import { useIsomorphicLayoutEffect } from '@unlikelystudio/react-hooks'

import { useClickOutsideContext } from '~/providers/ClickOutsideProvider'

const useClickOutside = (
  ref: RefObject<any>,
  callback: () => void,
  deps: any[] = [],
) => {
  const { canClickOutside } = useClickOutsideContext()

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (canClickOutside && ref.current && !ref.current.contains(e.target)) {
        callback()
      }
    },
    [canClickOutside, ...deps],
  )

  useIsomorphicLayoutEffect(() => {
    document.addEventListener('click', handleClick, { capture: true })
    document.addEventListener('touchstart', handleClick, { capture: true })

    return () => {
      document.removeEventListener('click', handleClick, { capture: true })
      document.removeEventListener('touchstart', handleClick, { capture: true })
    }
  })
}

export default useClickOutside
