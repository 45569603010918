import { usePresence } from 'framer-motion'
import React, { useRef } from 'react'

import { useSetStyle } from '@unlikelystudio/react-gsap-hooks'
import {
  useIsomorphicLayoutEffect,
  useSpring,
} from '@unlikelystudio/react-hooks'

import ImageWithPlaceholder, {
  ImageProps,
} from '~/components/ImageWithPlaceholder'

import css from './styles.module.scss'

export interface DesktopPanelImageProps extends ImageProps {
  className?: string
}

const DEFAULT_ALT = 'Navigation panel image'
export default function DesktopPanelImage({
  className,
  ...imageProps
}: DesktopPanelImageProps) {
  const imageRef = useRef<HTMLDivElement>(null)
  const [isPresent, safeToRemove] = usePresence()

  const setImageStyle = useSetStyle(imageRef)
  const [setProgressSpring] = useSpring({
    config: { interpolation: 'basic', friction: 5 },
    progress: 0,
    onUpdate: ({ progress }, { progress: prevProgress }) => {
      if (progress !== prevProgress) {
        setImageStyle({ opacity: progress })
        if (progress === 0) safeToRemove()
      }
    },
  })

  useIsomorphicLayoutEffect(() => {
    setProgressSpring({ progress: isPresent ? 1 : 0 })
  }, [isPresent])

  return (
    <div ref={imageRef} className={css.DesktopPanelImage}>
      {imageProps?.src && (
        <ImageWithPlaceholder
          className={css.image}
          layout="fill"
          objectFit="cover"
          sizesFromBreakpoints={[
            { columns: 6 },
            { breakpoint: 'max-grid-with', columns: 8 },
          ]}
          {...imageProps}
          alt={imageProps?.alt ?? DEFAULT_ALT}
        />
      )}
    </div>
  )
}

DesktopPanelImage.defaultProps = {}
