import { useTranslate } from 'react-polyglot'

import { useAlertContext } from '~/providers/AlertProvider'

interface Options {
  [key: string]: string
}

export type AlertPreset =
  | 'UNIDENTIFIED_CUSTOMER'
  | 'BAD_DOMAIN'
  | 'BLANK'
  | 'CONTAINS_HTML_TAGS'
  | 'CONTAINS_URL'
  | 'CUSTOMER_DISABLED'
  | 'INVALID'
  | 'INVALID_MULTIPASS_REQUEST'
  | 'NOT_FOUND'
  | 'PASSWORD_STARTS_OR_ENDS_WITH_WHITESPACE'
  | 'TAKEN'
  | 'TOKEN_INVALID'
  | 'TOO_LONG'
  | 'TOO_SHORT'
  | 'ALREADY_ENABLED'
  | 'GOOGLE_RECAPTCHA_ERROR'
  | 'JEM_ENTITY_SAVED'
  | 'JEM_EMAIL_SENT'
  | 'JEM_GIFT_EMAIL_SENT'
  | 'JEM_SERVER_ERRROR'
  | 'JEM_PASSWORD_RESET'
  | 'JEM_NEWSLETTER_SUBSCRIBE'
  | 'JEM_BOOKING_ERROR'
  | 'JEM_FORM_SUBMIT'

export default function useAlerts() {
  const { triggerAlert } = useAlertContext()
  const t = useTranslate()

  const getPreset = (preset: AlertPreset, titleOptions, messageOptions) => {
    switch (preset) {
      case 'UNIDENTIFIED_CUSTOMER':
        return {
          title: t('error_UNIDENTIFIED_CUSTOMER_title'),
          message: t('error_UNIDENTIFIED_CUSTOMER_message'),
          type: 'error',
        }
      case 'JEM_ENTITY_SAVED':
      case 'JEM_EMAIL_SENT':
      case 'JEM_NEWSLETTER_SUBSCRIBE':
      case 'JEM_GIFT_EMAIL_SENT':
      case 'JEM_FORM_SUBMIT':
        return {
          title: t(`success_${preset}_title`),
          message: t(`success_${preset}_message`),
          type: 'success',
        }
      default:
        return {
          title: t(`error_${preset}_title`),
          message: t(`error_${preset}_message`),
          type: 'error',
        }
    }
  }

  return (
    preset?: AlertPreset,
    titleOptions?: Options,
    messageOptions?: Options,
  ) => {
    triggerAlert(getPreset(preset, titleOptions, messageOptions))
  }
}
