import { useMutation, useQueryClient } from 'react-query'

import { checkoutUpdateItems } from '@unlikelystudio/provider-shopify'
import { useLatestCallback } from '@unlikelystudio/react-hooks'

import { CheckoutMutationHelperParams } from '~/hooks/checkout'
import useCheckoutId from '~/hooks/checkout/useCheckoutId'
import { GetCheckoutMutationResponse } from '~/hooks/checkout/useGetCheckout'
import useGetCheckoutQueryKey from '~/hooks/checkout/useGetCheckoutQueryKey'
import useOnCheckoutMutationSuccess from '~/hooks/checkout/useOnCheckoutMutationSuccess'
import useShopifyClient from '~/hooks/useShopifyClient'

export default function useUpdateItemsFromCheckout() {
  const shopify = useShopifyClient()
  const getCheckoutQueryKey = useGetCheckoutQueryKey()
  const checkoutId = useCheckoutId()
  const queryClient = useQueryClient()

  const onSuccess = useOnCheckoutMutationSuccess(
    'checkoutLineItemsUpdate',
    true,
  )

  const updateItemsFromCheckoutMutation = useMutation(checkoutUpdateItems, {
    mutationKey: 'update-items-from-checkout',
    onMutate: async ({ lineItems }) => {
      await queryClient.cancelQueries(getCheckoutQueryKey)

      const previousCheckout = queryClient.getQueryData(
        getCheckoutQueryKey,
      ) as GetCheckoutMutationResponse

      queryClient.setQueryData(
        getCheckoutQueryKey,
        (previousCheckout: GetCheckoutMutationResponse) => {
          const optimisticCheckout = { ...previousCheckout }

          if (optimisticCheckout?.data?.node?.lineItems) {
            optimisticCheckout?.data?.node?.lineItems?.edges?.map((item) => {
              const id = item?.node?.id
              const newLineItem = lineItems?.find((item) => item?.id === id)

              if (newLineItem?.quantity && item?.node?.quantity) {
                item.node.quantity = newLineItem.quantity
              }

              return item
            })
          }

          return optimisticCheckout
        },
      )

      return { previousCheckout }
    },
    onError: (
      _err,
      _newTodo,
      context: { previousCheckout: GetCheckoutMutationResponse },
    ) => {
      queryClient.setQueryData(getCheckoutQueryKey, context.previousCheckout)
    },
    onSuccess,
    onSettled: () => {
      queryClient.invalidateQueries(getCheckoutQueryKey)
    },
  })

  const updateItems = useLatestCallback(
    (
      updatedLineItems: CheckoutMutationHelperParams<
        typeof checkoutUpdateItems
      >['lineItems'],
    ) => {
      return updateItemsFromCheckoutMutation?.mutateAsync({
        client: shopify,
        checkoutId,
        lineItems: updatedLineItems,
      })
    },
  )

  return [updateItems, updateItemsFromCheckoutMutation] as const
}
