import { DependencyList, EffectCallback, useRef } from 'react'

import { useIsomorphicLayoutEffect } from '@unlikelystudio/react-hooks'

function useUpdateLayoutEffect(effect: EffectCallback, deps: DependencyList) {
  const firstRender = useRef(true)
  useIsomorphicLayoutEffect(() => {
    if (!firstRender.current) {
      effect()
    }
  }, deps)
  useIsomorphicLayoutEffect(() => {
    firstRender.current = false
  }, [])
}

export default useUpdateLayoutEffect
