import { ErrorOption } from 'react-hook-form'
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query'
import { removeFromWishlist } from '~/lib/handle-api'
import { getAccessToken } from '~/lib/token'

import { GetWishlistMutationResponse } from '~/hooks/account/useGetWishlist'
import useGetWishlistQueryKey from '~/hooks/account/useGetWishlistQueryKey'
import useOnMutationError from '~/hooks/account/useOnMutationError'
import useWishlistId from '~/hooks/account/useWishlistId'
import useCurrency from '~/hooks/useCurrency'

export default function useRemoveFromWishlist(
  mutationOptions?: UseMutationOptions<any, any, any>,
  setError?: (name?: string, error?: ErrorOption) => void,
) {
  const wishlistQueryKeys = useGetWishlistQueryKey()
  const wishlistId = useWishlistId()
  const queryClient = useQueryClient()
  const onMutationError = useOnMutationError(setError)
  const currency = useCurrency()

  return useMutation<any, any, any>(
    async ({ id }) => {
      const accessToken = getAccessToken(currency)

      if (!accessToken) return null

      return await removeFromWishlist({
        store: currency,
        accessToken,
        payload: {
          wishlistId,
          wishlistProductId: id,
        },
      })
    },
    {
      ...mutationOptions,
      onMutate: async ({ id }) => {
        await queryClient.cancelQueries(wishlistQueryKeys)

        const previousWishlist = queryClient.getQueryData(
          wishlistQueryKeys,
        ) as GetWishlistMutationResponse

        queryClient.setQueryData(
          wishlistQueryKeys,
          (previousWishlist: GetWishlistMutationResponse) => {
            const optimisticWishlist = { ...previousWishlist }

            if (optimisticWishlist?.data?.products) {
              optimisticWishlist.data.products =
                optimisticWishlist?.products?.filter(
                  (product) => product?.id !== id,
                )
            }

            return optimisticWishlist
          },
        )

        return { previousWishlist }
      },
      onSuccess: async (data, ...rest) => {
        //TODO: standardize error response
        const error = data?.errors?.[0]
        const errorPayload = { payload: [data?.errors?.[0]] }

        if (error) {
          return onMutationError(errorPayload, rest, mutationOptions?.onError)
        }

        mutationOptions?.onSuccess(data, ...rest)
      },
      onSettled: () => queryClient.invalidateQueries(wishlistQueryKeys),
      onError: (err, ...rest) => {
        onMutationError(err, rest, mutationOptions?.onError)
      },
    },
  )
}
