import Script from 'next/script'
import React from 'react'

function ThirdPartyScripts() {
  return (
    <>
      <Script
        strategy="beforeInteractive"
        src={`https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?features=ResizeObserver`}
      />

      <Script
        strategy="lazyOnload"
        src={`//static.cdn.prismic.io/prismic.js?repo=${process.env.NEXT_PUBLIC_PRISMIC_REPO_NAME}&new=true`}
      />

      <Script
        strategy="lazyOnload"
        src="https://cdn.jsdelivr.net/npm/@alma/widgets@3.x.x/dist/widgets.umd.js"
      />
    </>
  )
}

export default ThirdPartyScripts
