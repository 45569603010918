import { ImageProps } from 'next/image'
import { useMemo } from 'react'

import { useTheme } from '~/providers/ThemeProvider'

export interface Size {
  breakpoint?: number | string
  columns: number
}

export function vw(columns: number, maxColumns: number) {
  return `${Math.floor((columns / (maxColumns + 1)) * 100)}vw`
}

export function vwWithMaxWidth(
  columns: number,
  maxColumns: number,
  bp?: string,
) {
  return columns
    ? `${
        bp
          ? `(min-width: ${bp}) ${vw(columns, maxColumns)}`
          : `${vw(columns, maxColumns)}`
      }`
    : ''
}

export function useSizesFromBreakpoints(
  sizesFromBreakpoints,
  src: ImageProps['src'],
) {
  const { screens, mobile, desktop } = useTheme()

  const processedSizes = useMemo(() => {
    let processed

    if (sizesFromBreakpoints?.length > 0) {
      const main = sizesFromBreakpoints.find((size) => !size.breakpoint)
      if (!main)
        console.warn(
          `[Image] you don't specify a main size without breakpoint for ${src}`,
        )

      processed = sizesFromBreakpoints.reduce(
        (acc, { breakpoint, columns }, index) => {
          let processedBreakpoint
          // Breakpoint string from theme like 'md' or 'lg'
          if (
            breakpoint &&
            typeof breakpoint === 'string' &&
            screens?.[breakpoint]
          )
            processedBreakpoint = screens[breakpoint]
          // Breakpoint number like 1400
          else if (breakpoint && typeof breakpoint === 'number')
            processedBreakpoint = `${breakpoint}px`

          const grid = processedBreakpoint >= screens?.['md'] ? desktop : mobile

          return (
            acc +
            vwWithMaxWidth(
              columns,
              parseInt(grid.columns),
              processedBreakpoint,
            ) +
            (index !== sizesFromBreakpoints?.length - 1 ? ', ' : '')
          )
        },
        '',
      )
    }

    return processed
  }, [sizesFromBreakpoints])

  return processedSizes
}
