import classnames from 'classnames/bind'
import Cookies from 'js-cookie'
import React, { useEffect, useRef, useState } from 'react'
import { COOKIE_KEYS } from '~/lib/constants'

import ImageWithPlaceholder, {
  ImageWithPlaceholderProps,
} from '~/components/ImageWithPlaceholder'
import InlineCta, { InlineCtaProps } from '~/components/InlineCta'
import Ratio from '~/components/Ratio'
import RichText, { RichTextBlock } from '~/components/RichText'
import { CloseIcon } from '~/components/icons'

import { useStyle } from '~/providers/StyleProvider'

import useClickOutside from '~/hooks/useClickOutside'
import useLocale from '~/hooks/useLocale'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface EventPopInProps {
  className?: string
  displayed: boolean
  title?: RichTextBlock[]
  text?: RichTextBlock[]
  cta?: InlineCtaProps
  image?: ImageWithPlaceholderProps
}

const DEFAULT_ALT = 'Event popin alt'

function EventPopIn({
  className,
  displayed,
  title,
  text,
  image,
  cta,
}: EventPopInProps) {
  const locale = useLocale()
  const [isDisplayed, setIsDisplayed] = useState(false)
  const contentRef = useRef(null)
  const titleStyle = useStyle({ textPreset: 'text-16-18' })
  const textStyle = useStyle({ textPreset: 'text-12-14' })
  const key = `${COOKIE_KEYS.eventPopin}-${locale}`

  const onClose = () => {
    Cookies.set(key, '1')
    setIsDisplayed(false)
  }

  useClickOutside(contentRef, onClose)

  useEffect(() => {
    initialValueSetting()
  }, [])

  const initialValueSetting = () => {
    const value = Cookies.get(key) !== '1' && displayed
    setIsDisplayed(value)
  }

  return isDisplayed ? (
    <section className={cx(css.EventPopIn, className)}>
      <div className={css.background} />
      <div className={css.wrapper}>
        <div ref={contentRef} className={css.content}>
          <CloseIcon className={css.close} onClick={onClose} />
          <div className={css.texts}>
            {title && (
              <RichText className={cx(css.title, titleStyle)} render={title} />
            )}
            {text && (
              <RichText className={cx(css.text, textStyle)} render={text} />
            )}
            {cta?.href && (
              <InlineCta
                className={css.cta}
                theme="gold"
                {...cta}
                lineProps={{ initialLineState: 'underlined', theme: 'gold' }}
              />
            )}
          </div>
          {image && (
            <div className={css.image}>
              <Ratio className={css.ratio} ratio={345 / 540}>
                {(cn) => (
                  <ImageWithPlaceholder
                    className={cn}
                    layout="fill"
                    objectFit="cover"
                    sizesFromBreakpoints={[
                      { breakpoint: 'md', columns: 4 },
                      { columns: 3 },
                    ]}
                    {...image}
                    alt={image?.alt ?? DEFAULT_ALT}
                  />
                )}
              </Ratio>
            </div>
          )}
        </div>
      </div>
    </section>
  ) : null
}

EventPopIn.defaultProps = {}

export default EventPopIn
