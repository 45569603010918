import classnames from 'classnames/bind'
import React, { useRef } from 'react'

import { useTimeline, useSetStyle } from '@unlikelystudio/react-gsap-hooks'
import {
  useIsomorphicLayoutEffect,
  useMeasureObserver,
  useSpring,
  useWindowSize,
} from '@unlikelystudio/react-hooks'

import DesktopPanelRow from '~/components/Navigation/Desktop/DesktopPanelRow'
import { NavigationDataProps } from '~/components/Navigation/types'
import { ChevronIcon } from '~/components/icons'

import useBodyScrollLock from '~/hooks/useBodyScrollLock'
import useClickOutside from '~/hooks/useClickOutside'

import { customEaseOut } from '~/utils/easings'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface DesktopPanelProps extends NavigationDataProps {
  isOpen: boolean
  toggleMenu: () => void
  hideMenu: () => void
  headerHeight?: number
  sideImage: SideImageState
  setSideImage: (state: SideImageState) => void
}

export interface SideImageState {
  row: number | null
  item: number | null
}
export default function DesktopPanel({
  isOpen,
  toggleMenu,
  rows,
  hideMenu,
  sideImage,
  setSideImage,
}: DesktopPanelProps) {
  const panelRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const rowsWrapperRef = useRef<HTMLDivElement>(null)
  const rowsRef = useRef<HTMLDivElement>(null)
  const overlayRef = useRef<HTMLDivElement>(null)
  const rowsBounds = useMeasureObserver(rowsRef, 'borderBoxSize')
  const { width, height } = useWindowSize()

  const hasScroll = rowsBounds?.height > height

  const tl = useTimeline(
    {
      paused: true,
      restoreTime: true,
    },
    (tl) => {
      tl.fromTo(
        overlayRef.current,
        { autoAlpha: 0 },
        {
          autoAlpha: 1,
          duration: 0.35,
        },
        '0.35',
      )

      tl.to(
        overlayRef.current,
        {
          opacity: 1,
          duration: 0.35,
          ease: 'linear',
        },
        '0.5',
      )
    },
    [],
  )

  const setPanelStyle = useSetStyle(panelRef)
  const setWrapperStyle = useSetStyle(wrapperRef)

  const [setProgressSpring] = useSpring({
    config: {
      interpolation: 'basic',
      friction: 30 + width * 0.003,
      precisionStop: 0.000001,
      perfectStop: true,
    },
    progress: 0,

    onUpdate: ({ progress }, { progress: prevProgress }) => {
      if (progress !== prevProgress) {
        const easedProgress =
          progress > prevProgress ? progress : customEaseOut(progress)

        const x = 100 - easedProgress * 100

        setPanelStyle({ x: `${-x}%`, force3D: true })
        setWrapperStyle({ x: `${x}%`, force3D: true })
      }
    },
  })

  useIsomorphicLayoutEffect(() => {
    if (isOpen) tl.play()
    else tl.reverse()
    const progress = isOpen ? 1 : 0
    setProgressSpring({ progress })
  }, [isOpen])

  useIsomorphicLayoutEffect(() => {
    // Scroll rows container back to top on section change
    rowsWrapperRef.current.scroll({
      top: 0,
    })
    setSideImage(null)
  }, [rows])

  useBodyScrollLock(isOpen, rowsWrapperRef?.current, 'desktop-menu')

  useClickOutside(
    rowsRef,
    () => {
      if (isOpen && !sideImage) {
        setSideImage(null)
        toggleMenu()
      }
    },
    [isOpen, sideImage],
  )

  return (
    <>
      <div ref={overlayRef} className={css.overlay} />
      <div ref={panelRef} className={cx(css.Panel)}>
        <div ref={wrapperRef} className={css.wrapper}>
          <div ref={rowsWrapperRef} className={css.rowsWrapper}>
            <div className={css.panelBefore} />
            <div className={css.underline} />
            {rows?.length > 0 && (
              <div ref={rowsRef} className={css.rows}>
                {rows?.map((row, index) => (
                  <DesktopPanelRow
                    className={css.row}
                    key={`headerDesktopRow${index}`}
                    row={index}
                    hideMenu={hideMenu}
                    handleMouseLeave={() => setSideImage(null)}
                    handleMouseEnter={({ row, item }) => {
                      setSideImage({
                        row,
                        item,
                      })
                    }}
                    {...row}
                  />
                ))}
              </div>
            )}
          </div>
          <div className={css.gradiant} />
          {hasScroll && rows?.length > 0 && (
            <ChevronIcon className={css.chevronIcon} />
          )}
        </div>
      </div>
    </>
  )
}
