import Head from 'next/head'
import React from 'react'

import { ImageProps } from '~/components/Image'

import { getLang } from '~/utils/locales'

export interface Language {
  id: string
  uid: string
  lang: string
  url: string
  type: string
  locale?: string
  slugWithoutLocale?: string
}
export interface MetaProps extends Language {
  alternateLanguages: Language[]
  title?: string
  description?: string
  image?: ImageProps
}

const SITE_NAME = 'JEM - Jewellery Ethically Minded'

function generateAlternateMeta(language: Language) {
  if (!language || !language?.url || !language?.lang) return null

  return (
    <link
      rel="alternate"
      href={language?.url}
      hrefLang={getLang(language?.lang)}
      key={`alternate-${getLang(language?.lang)}`}
    />
  )
}

function Meta(meta: MetaProps) {
  const { title, description, image, url, alternateLanguages } = meta

  const metaTitle = title ?? SITE_NAME
  const metaDesc = description ?? null
  const metaImage = image ?? null
  const metasUrl = url ?? null

  return meta ? (
    <Head>
      <meta charSet="utf-8" />
      <title>{`${metaTitle}`}</title>
      {metaDesc && <meta name="description" content={metaDesc} />}
      <meta property="og:type" content="website"></meta>
      <meta property="og:title" content={`${metaTitle}`} key="title" />
      {metaDesc && <meta property="og:description" content={metaDesc} />}
      {metaImage && <meta property="og:image" content={metaImage?.src} />}

      {generateAlternateMeta(meta)}
      {alternateLanguages?.map((language) => generateAlternateMeta(language))}

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={metasUrl} />
      <meta property="twitter:title" content={`${metaTitle}`} />
      {metaDesc && <meta property="twitter:description" content={metaDesc} />}
      {metaImage && <meta property="twitter:image" content={metaImage?.src} />}
      {metasUrl && (
        <link rel="canonical" href={metasUrl} key={`canonical-${metasUrl}`} />
      )}
    </Head>
  ) : null
}

export default Meta
