import classnames from 'classnames/bind'
import React from 'react'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface SpinnerProps {
  className?: string
  color?: 'black' | 'white'
}

export default function Spinner({ className, color }: SpinnerProps) {
  return (
    <svg className={cx(css.spinner, className)} viewBox="0 0 50 50">
      <circle
        className={cx(color, css.path)}
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="2"
      />
    </svg>
  )
}
