import { useQuery } from 'react-query'
import { useDebouncedCallback } from 'use-debounce'
import {
  DEFAULT_UNIQUE_ID,
  PAIRED_KEY,
  PRODUCT_VARIATION_ATTRIBUTE_KEY,
} from '~/lib/constants'

import { Checkout } from '@unlikelystudio/ecommerce-typed/types/shopify'
import { checkoutGet } from '@unlikelystudio/provider-shopify'
import { useLatestCallback } from '@unlikelystudio/react-hooks'
import { IGraphQLResponse } from '@unlikelystudio/simpleql'

import { CheckoutProps } from '~/components/Panels/PanelCart/types'

import useCheckoutId from '~/hooks/checkout/useCheckoutId'
import useCheckoutProductUids from '~/hooks/checkout/useCheckoutProductUids'
import useGetCheckoutQueryKey from '~/hooks/checkout/useGetCheckoutQueryKey'
import useRemoveItemsFromCheckout from '~/hooks/checkout/useRemoveItemsFromCheckout'
import useUpdateItemsFromCheckout from '~/hooks/checkout/useUpdateItemsFromCheckout'
import useLocale from '~/hooks/useLocale'
import useShopifyClient from '~/hooks/useShopifyClient'

import { customAttributesAsObject } from '~/utils/custom-attributes'

import serializeCheckout from '~/data/serialize-checkout'
import serializePrice from '~/data/serialize-price'

export type GetCheckoutMutationResponse = IGraphQLResponse<{
  node: Checkout
}>

export default function useGetCheckout() {
  const shopify = useShopifyClient()
  const checkoutId = useCheckoutId()
  const locale = useLocale()

  const { uids } = useCheckoutProductUids()
  const queryKey = useGetCheckoutQueryKey()

  const [onUpdateMutation] = useUpdateItemsFromCheckout()
  const [onDeleteMutation] = useRemoveItemsFromCheckout()
  const debouncedOnUpdateMutation = useDebouncedCallback(onUpdateMutation, 500)
  const debouncedOnDeleteMutation = useDebouncedCallback(onDeleteMutation, 500)

  const getCheckout = useLatestCallback(
    async () => await checkoutGet({ client: shopify, checkoutId }),
  )

  const { isSuccess, isError, ...checkoutPayload } = useQuery(
    queryKey,
    getCheckout,
    { enabled: checkoutId ? true : false, refetchOnMount: false },
  )

  if (!checkoutPayload?.data && (isSuccess || isError)) {
    return [{ error: true } as CheckoutProps, checkoutPayload] as const
  }
  const checkout = checkoutPayload?.data?.data?.node as Checkout

  const customAttributes = customAttributesAsObject(
    checkout?.customAttributes ?? [],
  )

  const engravingProduct =
    checkout?.lineItems?.edges?.find(
      (item) =>
        item?.node?.variant?.id === customAttributes?.engravingVariantId,
    ) ?? null
  const engravingId = engravingProduct?.node?.variant?.id
  const engravingsQuantity = checkout?.lineItems?.edges?.filter(
    (p) => p?.node?.variant?.id === engravingId,
  )?.length
  const engravingPrice = serializePrice(
    locale,
    engravingProduct?.node?.variant?.priceV2?.currencyCode,
    engravingProduct?.node?.variant?.priceV2?.amount,
  )
  const engravingImageSrc =
    engravingProduct?.node?.variant?.image?.transformedSrc

  const serializedCheckoutPayload = serializeCheckout(checkout, uids, locale)

  const serializedCheckout: CheckoutProps = {
    ...serializedCheckoutPayload,
    engravingId,
    engravingsQuantity,
    engravingImageSrc,
    engravingPrice,
    productsQuantity:
      serializedCheckoutPayload?.productsQuantity - engravingsQuantity,
    products: serializedCheckoutPayload?.products
      ?.filter((p) => p?.variantId !== engravingId)
      ?.map((product) => {
        const id = product?.id
        const isEngraved = Boolean(product?.engraving)
        const variationId = product?.variationAttribute ?? null
        const isEngravingProduct = product?.variantId === engravingId
        const engravingSibling = serializedCheckoutPayload?.products?.find(
          (p) => {
            return (
              (p?.uniqueId === product?.uniqueId ||
                p?.uniqueId === product?.variationAttribute) &&
              p?.variantId === engravingId
            )
          },
        )

        const engravingPayload = {
          id: engravingSibling?.id,
          quantity: 0,
        }

        const restoredAttributes = []

        if (variationId) {
          restoredAttributes.push({
            key: PRODUCT_VARIATION_ATTRIBUTE_KEY,
            value: variationId,
          })
        } else {
          restoredAttributes.push({
            key: DEFAULT_UNIQUE_ID,
            value: product?.uniqueId,
          })
        }

        if (product?.isPaired) {
          restoredAttributes.push({
            key: PAIRED_KEY,
            value: 'true',
          })
        }

        return {
          ...product,
          isEngravingProduct,
          onDelete: () => {
            if (id) {
              debouncedOnDeleteMutation.cancel()
              const ids = [
                id,
                ...(isEngraved && engravingSibling
                  ? [engravingSibling?.id]
                  : []),
                ...(product?.isPaired ? [id] : []),
              ]

              onDeleteMutation(ids)
            }
          },
          onDeleteEngraving: () => {
            if (id && isEngraved) {
              debouncedOnUpdateMutation.cancel()
              onUpdateMutation([
                engravingPayload,
                {
                  id,
                  customAttributes: restoredAttributes,
                },
              ])
            }
          },
          ...(variationId && {
            onVariationDelete: (ids: string[]) => {
              const sibling = serializedCheckoutPayload?.products?.find(
                (p) => p?.variationAttribute === variationId,
              )
              const variationIsEngraved = sibling?.engraving || isEngraved

              if (ids) {
                debouncedOnDeleteMutation.cancel()
                onDeleteMutation(ids).then(() => {
                  if (variationIsEngraved) {
                    onUpdateMutation([engravingPayload])
                  }
                })
              }
            },
          }),
        }
      }),
  }

  return [serializedCheckout, checkoutPayload] as const
}
