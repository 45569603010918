import classnames from 'classnames/bind'
import { useRouter } from 'next/router'
import React from 'react'
import { useTranslate } from 'react-polyglot'

import DropdownSelect from '~/components/Form/DropdownSelect'

import { useGlobalData } from '~/providers/GlobalDataProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface LangSwitcherProps {
  className?: string
}

function LangSwitcher({ className }: LangSwitcherProps) {
  const { metas } = useGlobalData()
  const router = useRouter()
  const i18nLocales = router?.locales

  const t = useTranslate()

  if (i18nLocales?.length <= 1) {
    return null
  }

  const onLangSwitch = (locale: string) => {
    const selectedLang =
      metas?.alternateLanguages?.find((entry) => entry?.locale === locale) ?? {}

    if (
      !(Object.keys(selectedLang).length > 0) ||
      !selectedLang?.slugWithoutLocale
    ) {
      selectedLang.slugWithoutLocale = '/'
      selectedLang.uid = null
    }

    router.push(`${selectedLang?.slugWithoutLocale}`, undefined, { locale })
  }

  return (
    <div className={cx(className, css.LangSwitcher)}>
      <DropdownSelect
        className={cx(css.select)}
        currentValue={{
          label: t(`lang_switcher.${router.locale}`),
          value: router.locale,
        }}
        onChange={(item) => {
          const value = item?.value
          value && onLangSwitch(value)
        }}
        options={i18nLocales?.map((locale) => ({
          label: t(`lang_switcher.${locale}`),
          value: locale,
        }))}
        layout="light"
        langSelect
      />
    </div>
  )
}

LangSwitcher.defaultProps = {}

export default LangSwitcher
