import { DEFAULT_CURRENCY } from '~/lib/constants'

import multistore from 'config/multistore.json'

import useLocale from '~/hooks/useLocale'

export default function useCurrency() {
  const locale = useLocale()
  return multistore?.[locale]?.currency ?? DEFAULT_CURRENCY
}
