import NextImage, {
  ImageLoaderProps,
  ImageProps as NextImageProps,
} from 'next/image'
import React, { forwardRef } from 'react'

import isDev from '~/utils/is-dev'

import { Size, useSizesFromBreakpoints } from './maths'

export type ImageProps = Omit<NextImageProps, 'src' | 'width' | 'height'> & {
  sizesFromBreakpoints?: Size[]
  width?: number
  height?: number
  src: string
}

export const imageLoader = ({ src, width, quality }: ImageLoaderProps) => {
  return `${src}?auto=compress,format&fit=max&w=${width}&q=${quality}`
}

const Image = (
  {
    sizes,
    layout,
    sizesFromBreakpoints,
    width,
    height,
    className,
    quality = 60,
    ...props
  }: ImageProps,
  ref,
) => {
  const processedSizes = useSizesFromBreakpoints(
    sizesFromBreakpoints,
    props.src,
  )

  if (!props.src)
    isDev &&
      console.warn(
        `[WARNING] Image-Component is use without SRC ${JSON.stringify(props)}`,
      )

  return props.src ? (
    <div ref={ref} className={className}>
      <NextImage
        key={props?.src}
        sizes={processedSizes ? processedSizes : sizes}
        layout={layout}
        {...(layout === 'fill' ? {} : { width, height })}
        loader={(params) =>
          imageLoader({ ...params, quality: Number(quality) })
        }
        {...props}
      />
    </div>
  ) : null
}

export { default as Sizes } from './config'

const ImageWithRef = forwardRef<HTMLDivElement, ImageProps>(Image)

export default ImageWithRef
