import { imageLoader } from '~/components/Image'

export interface Image {
  src: string
  width: number
  height: number
  alt?: string
}

export interface ImageOptions {
  url?: string
  dimensions?: {
    width: number
    height: number
  }
  alt?: string
}

export interface LoaderParams {
  width: number
  quality: number
}

export interface PrismicImageOptions extends ImageOptions {
  card?: ImageOptions
  card1x?: ImageOptions
  card2x?: ImageOptions
}
export default function serializeImage(
  image: PrismicImageOptions,
  loaderParams?: LoaderParams,
): Image {
  const src = image?.url
  const dimensions = image?.dimensions ?? null
  const alt = image?.alt ?? null
  let newImageSrc: string

  if (src) {
    let url = new URL(src)
    newImageSrc = `${url?.origin}${url?.pathname}`
    newImageSrc = loaderParams
      ? imageLoader({
          src: newImageSrc,
          ...loaderParams,
        })
      : newImageSrc
  }

  return src
    ? {
        src: newImageSrc ?? src,
        width: dimensions?.width ?? null,
        height: dimensions?.height ?? null,
        alt,
      }
    : null
}
