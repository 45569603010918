import classnames from 'classnames/bind'
import { useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import Image, { ImageProps } from '~/components/Image'
import Loader from '~/components/Loader'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type ImageWithPlaceholderProps = ImageProps

function ImageWithPlaceHolder({
  className,
  src,
  ...rest
}: ImageWithPlaceholderProps) {
  const t = useTranslate()
  const placeholderRef = useRef(null)
  const loaderRef = useRef(null)
  const labelStyle = useStyle({ textPreset: 'title-9-11' })
  const [loaded, setLoaded] = useState<boolean>(false)

  if (!src) {
    return (
      <div className={css.imageUnavailable}>
        <span className={labelStyle}>{t('general.image_unavailable')}</span>
      </div>
    )
  }

  return (
    <div className={cx(className, css.ImageWithPlaceHolder)}>
      <Image
        {...rest}
        src={src}
        onLoadingComplete={() => {
          setLoaded(true)
        }}
      />
      <span ref={placeholderRef} className={cx(css.placeholder, { loaded })}>
        <Loader className={cx(css.loader, { loaded })} ref={loaderRef} />
      </span>
    </div>
  )
}

ImageWithPlaceHolder.defaultProps = {}

export type { ImageProps } from '~/components/Image'
export default ImageWithPlaceHolder
