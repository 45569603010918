import classnames from 'classnames'
import React, {
  createContext,
  DependencyList,
  ReactNode,
  useContext,
  useMemo,
} from 'react'

const Context = createContext({})

export interface StyleProviderProps {
  children: ReactNode | ReactNode[]
  value: object
}

export interface UseStyleOptions {
  [key: string]: string
}

function StyleProvider({ value, children }: StyleProviderProps) {
  return <Context.Provider value={value}>{children}</Context.Provider>
}

StyleProvider.defaultProps = {}

export function useStyle(options: UseStyleOptions, deps?: DependencyList) {
  const ctx = useContext(Context)
  return useMemo(
    () =>
      classnames(
        Object.keys(options)?.map((key) => {
          return ctx?.[key]?.[options?.[key]] ?? ''
        }) ?? '',
      ),
    deps || [options],
  )
}

export default StyleProvider
