import classnames from 'classnames/bind'
import {
  RichText as PrismicRichText,
  RichTextProps as PrismicRichTextProps,
} from 'prismic-reactjs'
import React, { forwardRef } from 'react'
import linkResolver, { LinkResolverDocParams } from '~/lib/link-resolver'

import useLocale from '~/hooks/useLocale'

import { richTextIsNotEmpty } from '~/utils/check-empty-string'

import getHtmlSerializer from './get-html-serializer'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface RichTextProps extends PrismicRichTextProps {
  className?: string
  children?: undefined
  innerRef?: any
}

function RichText({ className, innerRef, render, ...props }: RichTextProps) {
  const locale = useLocale()
  const htmlSerializer = getHtmlSerializer(locale)

  return (
    richTextIsNotEmpty(render) && (
      <div ref={innerRef} className={cx(className, css.RichText)}>
        <PrismicRichText
          htmlSerializer={htmlSerializer}
          linkResolver={(doc: LinkResolverDocParams) => linkResolver(doc)}
          render={render}
          {...props}
        />
      </div>
    )
  )
}

export { RichText as PrismicRichText } from 'prismic-reactjs'
export type { RichTextBlock } from 'prismic-reactjs'

export default forwardRef<HTMLDivElement, RichTextProps>((props, ref) => (
  <RichText innerRef={ref} {...props} />
))

export { prismicRichTextAsText } from './utils'
