import classnames from 'classnames/bind'
import React from 'react'

import ImageWithPlaceholder, {
  ImageWithPlaceholderProps,
} from '~/components/ImageWithPlaceholder'
import InlineCta, { InlineCtaProps } from '~/components/InlineCta'
import Ratio from '~/components/Ratio'
import RichText, { RichTextBlock } from '~/components/RichText'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface Slice404Props {
  className?: string
  title?: RichTextBlock[]
  image?: ImageWithPlaceholderProps
  cta?: InlineCtaProps
}

const DEFAULT_ALT = 'Not found element image'

function Slice404({ className, title, image, cta }: Slice404Props) {
  const gridStyle = useStyle({ grid: 'base-grid' })
  const titleStyle = useStyle({ textPreset: 'title-20-36' })

  return (
    <section className={cx(css.Slice404, className)}>
      <div className={cx(css.grid, gridStyle)}>
        {image && (
          <Ratio className={css.image} ratio={429 / 536}>
            {(cn) => (
              <ImageWithPlaceholder
                className={cn}
                layout="fill"
                objectFit="cover"
                sizesFromBreakpoints={[
                  { breakpoint: 'md', columns: 4 },
                  { columns: 4 },
                ]}
                {...image}
                alt={image?.alt ?? DEFAULT_ALT}
              />
            )}
          </Ratio>
        )}
        <div className={css.texts}>
          <div className={css.textsContent}>
            {title && (
              <RichText className={cx(css.title, titleStyle)} render={title} />
            )}
            {cta && (
              <InlineCta
                theme="gold"
                className={css.cta}
                {...cta}
                lineProps={{ theme: 'gold', initialLineState: 'underlined' }}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

Slice404.defaultProps = {}

export default Slice404
