import React, { createContext, ReactNode, useContext } from 'react'

import useGetWishlist, { Wishlist } from '~/hooks/account/useGetWishlist'

export const WishlistContext = createContext<ValueType>({})

interface WishlistProviderProps {
  children: ReactNode | ReactNode[]
}

export interface ValueType {
  wishlist?: Wishlist
  isSuccess?: boolean
}

export default function WishlistProvider({ children }: WishlistProviderProps) {
  const { data: wishlist, isSuccess } = useGetWishlist()

  const processedValue: ValueType = {
    wishlist,
    isSuccess,
  }

  return (
    <WishlistContext.Provider value={processedValue}>
      {children}
    </WishlistContext.Provider>
  )
}

export function useWishlist() {
  return useContext(WishlistContext)
}
