import classnames from 'classnames/bind'
import dynamic from 'next/dynamic'
import React from 'react'
import { useTranslate } from 'react-polyglot'

import { InputProps } from '~/components/Form/Input'
import NewsletterMockInput from '~/components/Form/NewsletterMockInput'
import MockSwapper from '~/components/MockSwapper'

import css from './styles.module.scss'

const NewsletterInput = dynamic(
  () => import('~/components/Form/NewsletterInput'),
)

const cx = classnames.bind(css)

export interface NewsletterProps extends InputProps {
  className?: string
}

function NewsletterManager({
  className,
  children,
  placeholder,
}: NewsletterProps) {
  const t = useTranslate()
  const processedPlaceholder = placeholder || t('form.newsletter_email')
  const newsletterProps = {
    placeholder: processedPlaceholder,
  }

  return (
    <div className={cx(className, css.Newsletter)}>
      {children}
      <MockSwapper
        RealComponent={NewsletterInput}
        MockComponent={NewsletterMockInput}
        realProps={{ autoFocus: true, ...newsletterProps }}
        mockProps={newsletterProps}
      />
    </div>
  )
}

NewsletterManager.defaultProps = {}

export default NewsletterManager
