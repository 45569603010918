import { useEffect, useRef, useState } from 'react'

import {
  useIsomorphicLayoutEffect,
  useMeasureObserver,
  useWindowSize,
} from '@unlikelystudio/react-hooks'

import { useCssVariables } from '~/providers/CssVariablesProvider'

export interface FixScrollbarOverflowParams {
  maxGridWidth?: number
}
/**
 * Set css variable with scrollbar size if needed
 */
export default function useFixScrollbarOverflow(
  fixScrollbarOverflowParams?: FixScrollbarOverflowParams,
) {
  const { update } = useCssVariables()

  const bodyRef = useRef(null)
  const bodyBounds = useMeasureObserver(bodyRef, 'borderBoxSize')
  const [scrollbarWidth, setScrollbarWidth] = useState(0)

  useIsomorphicLayoutEffect(() => {
    bodyRef.current = document.querySelector('body')
  }, [])

  useIsomorphicLayoutEffect(() => {
    const innerWidth = window.innerWidth
    const clientWidth = bodyBounds.width

    if (clientWidth === 0) return
    const scrollbarW = innerWidth - clientWidth
    scrollbarW >= 0 && setScrollbarWidth(scrollbarW)
  }, [bodyBounds.width, bodyBounds.height])

  useIsomorphicLayoutEffect(() => {
    update('scroll-bar', `${scrollbarWidth}px`)
  }, [scrollbarWidth])

  useIsomorphicLayoutEffect(() => {
    if (fixScrollbarOverflowParams?.maxGridWidth) {
      // const innerWidth = bodyBounds.width + scrollbarWidth
      const innerWidth = bodyBounds.width + scrollbarWidth
      const maxGridWithScrollBarWidth =
        fixScrollbarOverflowParams.maxGridWidth + scrollbarWidth

      const useVw = innerWidth < maxGridWithScrollBarWidth
      update(
        'scroll-bar-grid',
        `${useVw ? maxGridWithScrollBarWidth - innerWidth : 0}px`,
      )
    }
  }, [
    bodyBounds.width,
    bodyBounds.height,
    scrollbarWidth,
    fixScrollbarOverflowParams?.maxGridWidth,
  ])
}
