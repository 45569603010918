import { useRef } from 'react'

import { useIsomorphicLayoutEffect } from '@unlikelystudio/react-hooks'

function useOnKeyDown(key: number, callback: () => void) {
  const listener = useRef(null)

  useIsomorphicLayoutEffect(() => {
    listener.current = window.addEventListener('keydown', onKeyDown, {
      capture: true,
    })
    return () => {
      window.removeEventListener('keydown', onKeyDown, { capture: true })
      listener.current = null
    }
  }, [callback])

  const onKeyDown = (e) => {
    if (e.keyCode === key) {
      callback()
    }
  }
}

export default useOnKeyDown
