import classnames from 'classnames/bind'
import React from 'react'

import NewsletterInput from '~/components/Form/Newsletter'
import RichText, { RichTextBlock } from '~/components/RichText'

import { useStyle } from '~/providers/StyleProvider'

import FooterColumn, { FooterColumnProps } from './FooterColumn'
import FooterContact, { FooterContactProps } from './FooterContact'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface FooterProps {
  className?: string
  sections?: FooterColumnProps[]
  newsletter?: {
    title?: string
    text?: RichTextBlock[]
  }
  contact?: FooterContactProps
  recaptcha?: RichTextBlock[]
}

function Footer({
  className,
  sections,
  newsletter,
  contact,
  recaptcha,
}: FooterProps) {
  const gridStyle = useStyle({ grid: 'base-grid' })
  const titleStyle = useStyle({ textPreset: 'title-10' })
  const textStyle = useStyle({ textPreset: 'text-12-14', color: 'gray-47' })
  const recaptchaStyle = useStyle({ textPreset: 'text-8', color: 'gray-47' })
  return (
    <footer className={cx(className, css.Footer)}>
      <div className={cx(gridStyle, css.grid)}>
        {sections?.map((section, index: number) => (
          <FooterColumn
            key={`footer-column-${index}`}
            className={css.column}
            titleClassName={css.title}
            {...section}
          />
        ))}
        <NewsletterInput className={css.newsletter}>
          <h3 className={cx(titleStyle, css.title)}>{newsletter?.title}</h3>
          {newsletter?.text && (
            <RichText
              className={cx(textStyle, css.newsletterText)}
              render={newsletter.text}
            />
          )}
        </NewsletterInput>
        <FooterContact {...contact} />
        {recaptcha && (
          <RichText
            key="recaptcha"
            className={cx(css.recaptcha, recaptchaStyle)}
            render={recaptcha}
          />
        )}
      </div>
    </footer>
  )
}

Footer.defaultProps = {}

export default Footer
