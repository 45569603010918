import { useMemo } from 'react'
import { getShopifyMultistoreClient } from '~/lib/multistore'

import useLocale from '~/hooks/useLocale'

function hotfixUrl(url) {
  return url.replace(
    'jewellery-ethically-minded.myshopify.com',
    'checkout.jem-paris.com',
  )
}

export default function useShopifyClient() {
  const locale = useLocale()
  return useMemo(() => {
    const client = getShopifyMultistoreClient(locale)
    //@ts-ignore
    client.url = hotfixUrl(client.url)

    return client
  }, [locale])
}
