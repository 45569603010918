import React, { useMemo, createContext, useContext, ReactNode } from 'react'

const Context = createContext({})

export function useGlobalData(): any {
  const ctx = useContext(Context)
  return useMemo(() => ctx, [ctx])
}

export interface GlobalDataProviderProps {
  children: ReactNode | ReactNode[]
  value: object
}

export default function GlobalDataProvider({
  value,
  children,
}: GlobalDataProviderProps) {
  return <Context.Provider value={value}>{children}</Context.Provider>
}
