import classnames from 'classnames'
import { default as NextLink, LinkProps as NextLinkProps } from 'next/link'
import { useRouter } from 'next/router'
import React, { forwardRef, DOMAttributes, ReactNode } from 'react'

export interface LinkProps
  extends Omit<NextLinkProps, 'onMouseEnter' | 'onClick'>,
    DOMAttributes<any> {
  className?: string
  activeClassName?: string
  children?: ReactNode | ReactNode[]
  isExternal?: boolean
  target?: string | null
  innerRef?: any
}

function Link({
  className,
  children,
  href = '',
  as,
  prefetch,
  scroll,
  shallow,
  passHref,
  replace,
  isExternal,
  activeClassName,
  onMouseEnter,
  onClick,
  innerRef,
  ...rest
}: LinkProps) {
  const router = useRouter()
  const isActive = !isExternal && href === router?.asPath

  return isExternal ? (
    <a
      ref={innerRef}
      className={className}
      href={href as string}
      rel="noreferrer noopener"
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      {...rest}>
      {children}
    </a>
  ) : (
    <NextLink
      {...{
        href,
        as,
        replace,
        scroll,
        shallow,
        passHref,
        prefetch,
      }}>
      <a
        ref={innerRef}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        className={classnames(className, {
          [activeClassName]: activeClassName && isActive,
        })}
        {...rest}>
        {children}
      </a>
    </NextLink>
  )
}

Link.defaultProps = {}

export { default as linkResolver } from '~/lib/link-resolver'
export type { LinkResolverDocParams } from '~/lib/link-resolver'

export default forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
  <Link innerRef={ref} {...props} />
))
