import classnames from 'classnames/bind'
import React, { useRef } from 'react'

import { useTimeline } from '@unlikelystudio/react-gsap-hooks'
import { useIsomorphicLayoutEffect } from '@unlikelystudio/react-hooks'

import Link from '~/components/Link'
import { NavigationProps } from '~/components/Navigation'
import NavigationLogo from '~/components/Navigation/common/NavigationLogo'
import { PanelCart, PanelLogin } from '~/components/Panels'
import { AccountIcon, BagIcon, SearchIcon } from '~/components/icons'

import { usePanel } from '~/providers/PanelProvider'
import { useStyle } from '~/providers/StyleProvider'

import useGetCheckout from '~/hooks/checkout/useGetCheckout'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface MobileHeaderProps extends NavigationProps {
  isOpen: boolean
  toggleMenu: () => void
}

export default function MobileHeader({
  className,
  logo,
  isOpen,
  toggleMenu,
  searchLink,
  accountLink,
  isLogged,
}: MobileHeaderProps) {
  const [checkout] = useGetCheckout()
  const { add } = usePanel()
  const gridStyle = useStyle({ grid: 'base-grid' })
  const cartQuantityStyle = useStyle({
    textPreset: 'text-8',
    color: 'gray-20',
  })
  const firstLineRef = useRef<SVGRectElement>(null)
  const secondLineRef = useRef<SVGRectElement>(null)
  const thirdLineRef = useRef<SVGRectElement>(null)
  const fourthLineRef = useRef<SVGRectElement>(null)

  const tl = useTimeline(
    {
      paused: true,
    },
    (tl) => {
      const duration = 0.3
      const ease = 'expo'

      tl.fromTo(
        [secondLineRef.current, fourthLineRef.current],
        { xPercent: 0 },
        {
          xPercent: -100,
          duration,
          ease: `${ease}.in`,
          immediateRender: false,
        },
      )

      tl.fromTo(
        firstLineRef.current,
        { y: 0 },
        {
          y: 9,
          duration,
          ease: `${ease}.in`,
        },
        '<+0.2',
      )

      tl.fromTo(
        thirdLineRef.current,
        { y: 0 },
        {
          y: -1,
          duration,
          ease: `${ease}.in`,
        },
        '<',
      )

      tl.fromTo(
        firstLineRef.current,
        { rotate: 0 },
        {
          rotate: 45,
          transformOrigin: 'center',
          duration,
          ease: `${ease}.out`,
        },
      )

      tl.fromTo(
        thirdLineRef.current,
        { rotate: 0 },
        {
          rotate: -45,
          transformOrigin: 'center',
          duration,
          ease: `${ease}.out`,
        },
        '<',
      )
    },
  )

  useIsomorphicLayoutEffect(() => {
    if (isOpen) tl.restart()
    else tl.reverse()
  }, [isOpen])

  return (
    <div className={cx(className, css.MobileHeader)}>
      <div className={cx(gridStyle, css.container)}>
        <ul className={css.headerLeft}>
          <li
            className={css.menuIcon}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              toggleMenu()
            }}>
            <svg viewBox="0 0 16 16" width="16" height="16">
              <rect ref={firstLineRef} y="0" width="16" height="1" />
              <rect ref={secondLineRef} y="5" width="10" height="1" />
              <rect ref={thirdLineRef} y="10" width="16" height="1" />
              <rect ref={fourthLineRef} y="15" width="14" height="1" />
            </svg>
          </li>
          <li className={cx(css.link, css.iconLink)}>
            <Link aria-label="Link to search page" {...searchLink}>
              <SearchIcon />
            </Link>
          </li>
        </ul>
        <NavigationLogo className={css.logoWrapper} {...logo} />
        <ul className={css.headerRight}>
          <li className={cx(css.link, css.iconLink)}>
            <Link
              aria-label="Link to account page"
              {...accountLink}
              onClick={(e) => {
                if (!isLogged) {
                  e.preventDefault()
                  add({ component: <PanelLogin /> })
                }
              }}>
              <AccountIcon />
            </Link>
          </li>
          <li
            className={cx(css.link, css.iconLink)}
            onClick={(e) => add({ component: <PanelCart /> })}>
            {checkout.productsQuantity > 0 && (
              <span className={cx(cartQuantityStyle, css.cartQuantity)}>
                <span>{checkout.productsQuantity}</span>
              </span>
            )}
            <BagIcon />
          </li>
        </ul>
      </div>
    </div>
  )
}

MobileHeader.defaultProps = {}
