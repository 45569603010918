import React from 'react'

import { NewsletterProps } from '~/components/Form/Newsletter'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

function NewsletterMockInput({ placeholder }: NewsletterProps) {
  const inputTextStyle = useStyle({
    textPreset: 'input-14-12',
    color: 'gray-47',
  })

  return (
    <div className={css.NewsletterMockInput}>
      <p className={inputTextStyle}>{placeholder}*</p>
      <button className={css.arrowIcon} type="submit">
        <img width={20} height={5} src="/icons/arrow.svg" alt="Arrow" />
      </button>
    </div>
  )
}

NewsletterMockInput.defaultProps = {}

export default NewsletterMockInput
