import Cookies from 'js-cookie'
import { COOKIE_KEYS } from '~/lib/constants'

import { CustomerAccessToken } from '@unlikelystudio/ecommerce-typed/types/shopify'

import { getBranchEnv } from '~/utils/get-branch-env'

export function setAccessToken(token: CustomerAccessToken, currency: string) {
  if (token) {
    const env = getBranchEnv()

    Cookies.set(`${COOKIE_KEYS.customerAccessToken}_${currency}`, token, {
      expires: 14,
      secure: env === 'production',
    })
  }
}

export function getAccessToken(currency: string) {
  const accessToken = `${COOKIE_KEYS.customerAccessToken}_${currency}`
  return Cookies.get(accessToken)
}

export function removeAccessToken(currency: string) {
  const accessToken = `${COOKIE_KEYS.customerAccessToken}_${currency}`
  Cookies.remove(accessToken)
}
