import classnames from 'classnames/bind'
import React, { useRef } from 'react'

import { NavigationProps } from '~/components/Navigation'

import DesktopHeader from './DesktopHeader'
import css from './styles.module.scss'

const cx = classnames.bind(css)

interface NavigationDesktopProps extends NavigationProps {
  hasScrolled?: boolean
}

function NavigationDesktop({
  className,
  hasScrolled,
  headerLight,
  ...rest
}: NavigationDesktopProps) {
  const backgroundRef = useRef(null)

  return (
    <div className={cx(className, css.DesktopNavigation)}>
      <div className={css.fixedHeader}>
        <div
          ref={backgroundRef}
          className={cx(css.background, { headerLight, hasScrolled })}
        />
        <DesktopHeader className={cx({ hasScrolled })} {...rest} />
      </div>
    </div>
  )
}

NavigationDesktop.defaultProps = {}

export default NavigationDesktop
