import classnames from 'classnames/bind'
import React, { useRef, forwardRef } from 'react'

import { useSetStyle } from '@unlikelystudio/react-gsap-hooks'
import { useIsomorphicLayoutEffect } from '@unlikelystudio/react-hooks'
import { useSpring } from '@unlikelystudio/react-hooks'

import {
  BaseProps,
  InlineCtaTextPresets,
  InlineCtaThemes,
} from '~/components/InlineCta'
import Link, { LinkProps } from '~/components/Link'

import useUnderline from '~/hooks/useUnderline'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type NavigationRowLinkType = 'primary' | 'secondary' | 'dropdown'

export interface NavigationRowLinkCallbacks {
  handleMouseEnter?: ({ row, item }?: { row?: number; item?: number }) => void
  handleMouseLeave?: () => void
  handleClick?: () => void
}
export interface NavigationRowLinkProps
  extends BaseProps,
    NavigationRowLinkCallbacks {
  className?: string
  link: LinkProps
  theme?: InlineCtaThemes
  innerRef?: any
}

type NavigationRowLinkTypes = {
  [key in NavigationRowLinkType]: InlineCtaTextPresets
}
const NavigationRowLinkTypes: NavigationRowLinkTypes = {
  primary: 'cta-11',
  secondary: 'cta-10',
  dropdown: 'cta-GT-18',
}

function NavigationRowLink({
  className,
  link,
  onMouseEnter,
  onMouseLeave,
  handleMouseEnter,
  handleMouseLeave,
  innerRef,
  ...rest
}: NavigationRowLinkProps) {
  const linkRef = useRef<HTMLAnchorElement>(null)
  const lineRef = useRef<HTMLDivElement>(null)

  const { shouldUnderline, ...underlineMouseEvents } = useUnderline({
    onMouseEnter,
    onMouseLeave,
  })

  const setLinkStyle = useSetStyle(linkRef)
  const setLineStyle = useSetStyle(lineRef)

  const [setProgressSpring] = useSpring({
    config: { interpolation: 'linear', friction: 8 },
    progress: 0,
    onUpdate: ({ progress }, { progress: prevProgress }) => {
      if (progress !== prevProgress) {
        const x = 100 - progress * 100
        setLinkStyle({ x: `${progress * 60}`, force3D: true })
        setLineStyle({ opacity: progress, x: `${-x}%`, force3D: true })
      }
    },
  })

  useIsomorphicLayoutEffect(() => {
    const progress = shouldUnderline ? 1 : 0
    setProgressSpring({ progress })
  }, [shouldUnderline])

  return (
    <li ref={innerRef} className={cx(className, css.RowLink)} {...rest}>
      <div
        className={css.wrapper}
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={handleMouseLeave}>
        <div {...underlineMouseEvents}>
          <Link className={css.wrapper} {...link}>
            <span ref={lineRef} className={css.line} />
            <span ref={linkRef} className={css.link}>
              {link?.children}
            </span>
          </Link>
        </div>
      </div>
    </li>
  )
}

NavigationRowLink.defaultProps = {
  type: 'primary',
  theme: 'black',
}

export default forwardRef<any, NavigationRowLinkProps>((props, ref) => (
  <NavigationRowLink innerRef={ref} {...props} />
))
