import { useMutation, useQueryClient } from 'react-query'

import { checkoutRemoveItems } from '@unlikelystudio/provider-shopify'
import { useLatestCallback } from '@unlikelystudio/react-hooks'

import { CheckoutMutationHelperParams } from '~/hooks/checkout'
import useCheckoutId from '~/hooks/checkout/useCheckoutId'
import { GetCheckoutMutationResponse } from '~/hooks/checkout/useGetCheckout'
import useGetCheckoutQueryKey from '~/hooks/checkout/useGetCheckoutQueryKey'
import useOnCheckoutMutationSuccess from '~/hooks/checkout/useOnCheckoutMutationSuccess'
import useShopifyClient from '~/hooks/useShopifyClient'

export default function useRemoveItemsFromCheckout() {
  const shopify = useShopifyClient()
  const checkoutId = useCheckoutId()
  const queryClient = useQueryClient()

  const getCheckoutQueryKey = useGetCheckoutQueryKey()
  const onSuccess = useOnCheckoutMutationSuccess('checkoutLineItemsRemove')

  const removeItemsFromCheckoutMutation = useMutation(checkoutRemoveItems, {
    mutationKey: 'remove-items-from-checkout',
    // Optimistically removing the line item from the checkout
    onMutate: async ({ lineItemIds }) => {
      await queryClient.cancelQueries(getCheckoutQueryKey)

      const previousCheckout = queryClient.getQueryData(
        getCheckoutQueryKey,
      ) as GetCheckoutMutationResponse

      queryClient.setQueryData(
        getCheckoutQueryKey,
        (checkout: GetCheckoutMutationResponse) => {
          const optimisticCheckout = { ...checkout }
          if (optimisticCheckout?.data?.node?.lineItems) {
            optimisticCheckout.data.node.lineItems.edges =
              optimisticCheckout.data.node.lineItems.edges?.filter?.(
                (lineItem) => {
                  return lineItemIds?.find?.((lineItemId) => {
                    return lineItemId !== lineItem?.node?.id
                  })
                },
              )
          }
          return optimisticCheckout
        },
      )

      return { previousCheckout }
    },
    // Rollback to the previous state if there is an error
    onError: (
      _err,
      _newTodo,
      context: { previousCheckout: GetCheckoutMutationResponse },
    ) => {
      queryClient.setQueryData(getCheckoutQueryKey, context.previousCheckout)
    },
    onSuccess,
    onSettled: () => {
      queryClient.invalidateQueries(getCheckoutQueryKey)
    },
  })

  const removeItems = useLatestCallback(
    (
      lineItemIds: CheckoutMutationHelperParams<
        typeof checkoutRemoveItems
      >['lineItemIds'],
    ) => {
      return removeItemsFromCheckoutMutation?.mutateAsync({
        client: shopify,
        checkoutId,
        lineItemIds,
      })
    },
  )

  return [removeItems, removeItemsFromCheckoutMutation] as const
}
