import classnames from 'classnames/bind'
import React from 'react'

import Alert, { AlertProps } from '~/components/Alert'

import { useAlertContext } from '~/providers/AlertProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface AlertCenterProps {
  className?: string
}

function AlertCenter({ className }: AlertCenterProps) {
  const { alerts, removeAlert } = useAlertContext()

  return (
    <section className={cx(className, css.AlertCenter)}>
      {alerts.map(({ uid, ...alert }: AlertProps) => {
        return (
          <Alert
            key={uid}
            onClose={() => removeAlert(uid)}
            className={css.alert}
            {...alert}
          />
        )
      })}
    </section>
  )
}

AlertCenter.defaultProps = {}

export default AlertCenter
