import classnames from 'classnames/bind'
import React from 'react'

import { useIsHover } from '@unlikelystudio/react-hooks'

import Image, { ImageProps } from '~/components/Image'
import Link, { LinkProps } from '~/components/Link'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface SocialProps {
  link?: LinkProps
  icon?: ImageProps
}

interface PlatformProps {
  label?: string
  value?: string
}

export interface FooterContactProps {
  className?: string
  label?: string
  platforms?: PlatformProps[]
  socials?: SocialProps[]
}

function FooterContact({
  className,
  label,
  platforms,
  socials,
}: FooterContactProps) {
  const [, mouseEvents] = useIsHover()
  const textStyle = useStyle({ textPreset: 'text-16-13' })
  const platformLabelStyle = useStyle({
    textPreset: 'text-16-13',
    color: 'gray-47',
  })

  const renderPlatformItem = ({ label, value }, index: number) => {
    return (
      <div key={index} className={css.platformWrapper}>
        <p className={cx(platformLabelStyle, css.platform)}>{label}</p>
        <p className={textStyle}>{value}</p>
      </div>
    )
  }

  return (
    <div className={cx(className, css.FooterContact)}>
      <div className={css.contact}>
        <h3 className={cx(textStyle, css.text)}>{label}</h3>
        <div className={css.platforms}>
          {platforms?.map(renderPlatformItem)}
        </div>
      </div>
      <div className={css.socials}>
        {socials?.map((social, index: number) => {
          const url = social?.link?.href as string
          const platform = url?.split('.')?.[1] ?? null

          return (
            <Link
              className={cx(css.icon)}
              key={`social-${index}`}
              aria-label={platform}
              {...mouseEvents}
              {...social?.link}>
              <Image
                {...social?.icon}
                alt={social?.icon?.alt ?? `${platform} logo`}
                sizesFromBreakpoints={[{ columns: 0.5 }]}
                layout="intrinsic"
                className={css.image}
              />
            </Link>
          )
        })}
      </div>
    </div>
  )
}

FooterContact.defaultProps = {}

export default FooterContact
