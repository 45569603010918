import Cookies from 'js-cookie'
import { useMutation } from 'react-query'
import { COOKIE_KEYS } from '~/lib/constants'

import { checkoutCreate } from '@unlikelystudio/provider-shopify'

import { CheckoutMutationHelperParams } from '~/hooks/checkout'
import useCurrency from '~/hooks/useCurrency'
import useShopifyClient from '~/hooks/useShopifyClient'

export default function useCreateCheckout() {
  const currency = useCurrency()
  const storefrontShopify = useShopifyClient()

  return useMutation(
    async (
      options: CheckoutMutationHelperParams<
        typeof checkoutCreate
      >['checkoutCreateInput'],
    ) => checkoutCreate({ client: storefrontShopify, ...options }),
    {
      onSuccess: (res) => {
        const checkoutId = res?.data?.checkoutCreate?.checkout?.id

        if (checkoutId) {
          Cookies.set(`${COOKIE_KEYS.checkoutId}-${currency}`, checkoutId)
        }
      },
    },
  )
}
