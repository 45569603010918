import classnames from 'classnames/bind'
import React, { forwardRef } from 'react'

import MobileHeader, { MobileHeaderProps } from './MobileHeader'
import css from './styles.module.scss'

const cx = classnames.bind(css)

interface NavigationMobileProps extends MobileHeaderProps {
  innerRef?: any
  hasScrolled?: boolean
}
function NavigationMobile({
  className,
  hasScrolled,
  innerRef,
  ...rest
}: NavigationMobileProps) {
  return (
    <div ref={innerRef} className={cx(className, css.Navigation)}>
      <MobileHeader className={cx({ hasScrolled })} {...rest} />
    </div>
  )
}

NavigationMobile.defaultProps = {}

export default forwardRef<any, NavigationMobileProps>((props, ref) => (
  <NavigationMobile innerRef={ref} {...props} />
))
