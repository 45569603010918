import mitt from 'mitt'
import React, { createContext, ReactNode, useContext, useState } from 'react'

const emitter = mitt()
export const Context = createContext(emitter)

export function useTracking() {
  return useContext(Context)
}

export interface TrackingProviderProps {
  children?: ReactNode | ReactNode[]
}

function TrackingProvider({ children }: TrackingProviderProps) {
  const [tracking] = useState(emitter)
  return <Context.Provider value={tracking}>{children}</Context.Provider>
}

TrackingProvider.defaultProps = {}

export default TrackingProvider
