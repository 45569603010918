import classnames from 'classnames/bind'
import React, { forwardRef } from 'react'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface LoaderProps {
  className?: string
  innerRef?: any
}

function Loader({ className, innerRef }: LoaderProps) {
  return <div ref={innerRef} className={cx(css.Loader, className)}></div>
}

Loader.defaultProps = {}

export default forwardRef<HTMLDivElement, LoaderProps>((props, ref) => (
  <Loader innerRef={ref} {...props} />
))
