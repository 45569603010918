import React, {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from 'react'

import { FacetsResult } from '@unlikelystudio/horizon-algolia'

import { FilterSections } from '~/components/Filters/FilterItem/types'

import FiltersReducer, { FiltersReducerAction } from './reducer'

export enum ActionType {
  ADD_FILTER = 'ADD_FILTER',
  ADD_FILTERS = 'ADD_FILTERS',
  REMOVE_FILTER = 'DECREMENT_QTY',
  REMOVE_ALL = 'REMOVE_ALL',
  SORT_BY = 'SORT_BY',
  SET_DEFAULT_FILTERS = 'SET_DEFAULT_FILTERS',
  SET_FACETS = 'SET_FACETS',
}

export enum SortBy {
  ASC = 'price_asc',
  DESC = 'price_desc',
  DEFAULT = 'score',
}

const InitialState = {
  filters: {},
  defaultFilters: {},
  facets: null,
  sortBy: SortBy.DEFAULT,
}

const FiltersContext = createContext<FiltersContent>({
  ...InitialState,
  dispatch: () => {},
})

export interface FiltersState {
  filters: {
    [key in FilterSections]?: string[]
  }
  defaultFilters: {
    [key in FilterSections]?: string[]
  }
  facets: FacetsResult[] | null
  sortBy: SortBy | null
}

interface FiltersContent extends FiltersState {
  dispatch: Dispatch<FiltersReducerAction>
}

interface FiltersProviderProps {
  children: ReactNode | ReactNode[]
}

export default function FiltersProvider({ children }: FiltersProviderProps) {
  const [data, dispatch] = useReducer(FiltersReducer, InitialState)

  const value = {
    filters: data.filters,
    defaultFilters: data.defaultFilters,
    facets: data.facets,
    sortBy: data.sortBy,
    dispatch,
  }

  return (
    <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
  )
}

export function useFilters() {
  return useContext(FiltersContext)
}
