import { useEffect } from 'react'

import useCheckoutId from '~/hooks/checkout/useCheckoutId'
import useCreateCheckout from '~/hooks/checkout/useCreateCheckout'
import useGetCheckout from '~/hooks/checkout/useGetCheckout'

export default function useAutoCreateCheckout() {
  const checkoutId = useCheckoutId()
  const [{ completed, error }] = useGetCheckout()
  const { mutate: createCheckout } = useCreateCheckout()

  useEffect(() => {
    if (!checkoutId) {
      createCheckout({})
    }
  }, [checkoutId])

  useEffect(() => {
    if (completed || error) {
      createCheckout({})
    }
  }, [completed, error])
}
