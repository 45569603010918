import classnames from 'classnames/bind'
import React, { DOMAttributes, ReactChild } from 'react'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface RatioProps extends DOMAttributes<any> {
  className?: string
  preset?: string
  ratio?: number
  children: (className: string) => ReactChild
}

function Ratio({ ratio, className, preset, children, ...rest }: RatioProps) {
  const presetStyle = useStyle({
    ratio: preset,
  })
  const containerStyle = cx(className, css.ratioContainer, presetStyle)

  return (
    <div {...rest} className={cx(css.Ratio, className)}>
      <div
        className={containerStyle}
        style={ratio ? { paddingBottom: `${(1 / ratio) * 100}%` } : {}}>
        {children(css.children)}
      </div>
    </div>
  )
}

Ratio.defaultProps = {}

export default Ratio
