import classnames from 'classnames/bind'
import React from 'react'

import Link, { LinkProps } from '~/components/Link'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ButtonProps {
  className?: string
  link?: LinkProps
  text?: any
  onClick?: (e: React.MouseEvent) => void
}

function Button({ className, link, text, onClick }: ButtonProps) {
  const bottomLinkStyle = useStyle({ textPreset: 'cta-10' })
  const classNames = cx(className, bottomLinkStyle, css.Button)

  return link ? (
    <Link className={cx(classNames)} {...link} onClick={onClick} />
  ) : (
    <button className={cx(classNames)} onClick={onClick}>
      {text}
    </button>
  )
}

Button.defaultProps = {}

export default Button
