import classnames from 'classnames/bind'
import React, { HTMLAttributes, useRef } from 'react'
import { useTranslate } from 'react-polyglot'

import { useTimeline } from '@unlikelystudio/react-gsap-hooks'

import { useStyle } from '~/providers/StyleProvider'

import { ReactComponent as CrossIcon } from './cross.svg'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface AlertProps {
  uid?: number
  className?: string
  title?: string
  message?: string
  type?: 'error' | 'success' | string
  onClose?: HTMLAttributes<any>['onClick']
}

function Alert({ className, type, title, message, onClose }: AlertProps) {
  const titleTextStyle = useStyle({ textPreset: 'title-18' })
  const messageTextStyle = useStyle({ textPreset: 'text-14' })

  const alertRef = useRef()
  const contentRef = useRef()

  const closeTl = useTimeline({ paused: true }, (tl) => {
    tl.to(alertRef.current, {
      opacity: 0,
      duration: 0.08,
      ease: 'linear',
    })
    tl.add(() => {
      onClose?.(null)
    })
  })

  useTimeline({}, (tl) => {
    tl.fromTo(
      alertRef.current,
      {
        yPercent: 100,
      },
      {
        yPercent: 0,
        force3D: true,
        duration: 0.8,
        ease: 'expo.out',
      },
    )
    tl.fromTo(
      contentRef.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.3,
        ease: 'linear',
      },
      '<0.2',
    )
    tl.add(() => closeTl.play(), '+=2.5')
  })

  const t = useTranslate()

  return (
    <div ref={alertRef} className={cx(className, css.Alert, type)}>
      <div ref={contentRef}>
        <button
          className={css.closeButton}
          aria-label={t('label_close', { _: 'Close' })}
          onClick={(e) => {
            closeTl.play()
            onClose?.(e)
          }}>
          <CrossIcon width={10} height={10} className={css.closeIcon} />
        </button>
        {title && <div className={cx(titleTextStyle, css.title)}>{title}</div>}
        {message && (
          <div className={cx(messageTextStyle, css.message)}>{message}</div>
        )}
      </div>
    </div>
  )
}

Alert.defaultProps = {}

export default Alert
