import React, { ReactNode, useEffect } from 'react'

import useMockSwap from '~/hooks/useMockSwap'

export interface MockSwapperProps {
  className?: string
  RealComponent: any
  MockComponent: any
  realProps?: any
  mockProps?: any
  children?: ReactNode | ReactNode[]
  isTriggeredOnClick?: boolean
  isTriggeredInView?: boolean
  isInView?: boolean
}

function MockSwapper({
  className,
  RealComponent,
  MockComponent,
  realProps,
  mockProps,
  children,
  isTriggeredOnClick,
  isTriggeredInView,
  isInView,
}: MockSwapperProps) {
  const { loaded, setSwapMock } = useMockSwap(RealComponent)

  const divProps = {
    className,
    onClick: null,
    onFocus: null,
  }

  if (isTriggeredOnClick) {
    divProps.onClick = () => {
      setSwapMock(true)
    }

    divProps.onFocus = () => {
      setSwapMock(true)
    }
  }

  useEffect(() => {
    if (!isTriggeredInView || loaded) {
      return
    }

    if (isTriggeredInView && isInView) {
      setSwapMock(true)
    }
  }, [isTriggeredInView, isInView, loaded])

  return (
    <div {...divProps}>
      {loaded ? (
        <RealComponent {...realProps}>{children}</RealComponent>
      ) : (
        <MockComponent {...mockProps}>{children}</MockComponent>
      )}
    </div>
  )
}

MockSwapper.defaultProps = {
  isTriggeredOnClick: true,
  isTriggeredInView: false,
  isInView: false,
}

export default MockSwapper
