import { error } from 'console'
import { useState, useEffect } from 'react'
import { useTranslate } from 'react-polyglot'

import Alert from '~/components/Alert'

import { AlertPreset } from '~/hooks/useAlerts'

export type Errors = Record<string, AlertPreset>

export default function useCustomerErrors() {
  const t = useTranslate()
  const [errorMsg, setErrorMsg] = useState(null)

  return (errors: any, formSetError) => {
    const errsFields: Errors = {}
    const errsGlobals: Errors = {}

    if (errors?.payload) {
      const pushToObj = (key, obj, el) => {
        if (obj[key]) obj[key].push(el)
        else obj[key] = [el]
      }

      errors.payload?.forEach((err) => {
        const fieldName = err?.field?.[1]
        if (fieldName && err.code) pushToObj(fieldName, errsFields, err.code)
        else if (err.code) errsGlobals[err.code] = err.code
        else errsGlobals['JEM_SERVER_ERRROR'] = 'JEM_SERVER_ERRROR'
      })

      Object.keys(errsFields)?.map((key) => {
        const obj = errsFields[key]
        formSetError &&
          formSetError(key, {
            types: obj,
          })
      })
    }

    return {
      errsFields,
      errsGlobals,
    }
  }
}
