import React, { useState, ReactNode, createContext } from 'react'
import classnames from 'classnames/bind'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export const AccordionContext = createContext(null)

export interface AccordionProps {
  className?: string
  index?: number
  children?: ReactNode | ReactNode[]
}

function Accordion({ className, index, children }: AccordionProps) {
  const [currentIndex, setCurrentIndex] = useState(index ?? null)

  return (
    <AccordionContext.Provider value={{ currentIndex, setCurrentIndex }}>
      <div className={cx(className, css.Accordion)}>{children}</div>
    </AccordionContext.Provider>
  )
}

export default Accordion
