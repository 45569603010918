import classnames from 'classnames/bind'
import React, { useRef } from 'react'

import { useTimeline } from '@unlikelystudio/react-gsap-hooks'
import { useIsomorphicLayoutEffect } from '@unlikelystudio/react-hooks'

import useUpdateLayoutEffect from '~/hooks/useUpdateLayoutEffect'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type LineThemes = 'black' | 'gray' | 'gold' | 'inherit'
export interface LineProps {
  className?: string
  theme?: LineThemes
  thickness?: 1 | 2
  isVisible?: boolean
  initialLineState?: 'underlined' | 'none'
}

function Line({
  className,
  theme,
  isVisible,
  thickness,
  initialLineState,
}: LineProps) {
  const lineRef = useRef<HTMLDivElement>()
  const isUnderlined = initialLineState === 'underlined'

  const tl = useTimeline(
    { paused: true },
    (tl) => {
      const [fromScaleX, toScaleX] =
        (isUnderlined && isVisible) || (!isUnderlined && isVisible)
          ? [0, 1]
          : [1, 0]

      tl.fromTo(
        lineRef.current,
        { scaleX: fromScaleX },
        {
          scaleX: toScaleX,
          immediateRender: false,
          duration: 0.4,
          force3D: true,
          ease: 'power2.inOut',
        },
      )
    },
    [isVisible, initialLineState],
  )

  useIsomorphicLayoutEffect(() => {
    if (isVisible) tl.play()
  })

  useUpdateLayoutEffect(() => {
    if ((isUnderlined && isVisible) || !isUnderlined) {
      tl.restart()
    }
  }, [isVisible])

  return (
    <span
      style={{ height: `${thickness ?? 1}px` }}
      className={cx(className, css.Line, {
        isUnderlined,
        [`theme-${theme}`]: theme,
      })}>
      <span ref={lineRef} className={css.lineProgress} />
    </span>
  )
}

Line.defaultProps = {
  theme: 'black',
  initialLineState: 'none',
}

export default Line
