import classnames from 'classnames/bind'
import React from 'react'

import Spinner from '~/components/Spinner'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface PanelLoadingProps {
  className?: string
}

function PanelLoading({ className }: PanelLoadingProps) {
  return (
    <div className={cx(css.PanelLoading, className)}>
      <div className={css.spinner}>
        <Spinner />
      </div>
    </div>
  )
}

PanelLoading.defaultProps = {}

export default PanelLoading
