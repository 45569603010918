import classnames from 'classnames/bind'
import React, { DOMAttributes, ReactNode, forwardRef } from 'react'
import { UrlObject } from 'url'

import Line, { LineProps, LineThemes } from '~/components/Line'
import Link, { LinkProps } from '~/components/Link'

import { useStyle } from '~/providers/StyleProvider'

import useUnderline from '~/hooks/useUnderline'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type InlineCtaTextPresets =
  | 'cta-10'
  | 'cta-11'
  | 'cta-12'
  | 'cta-11-13'
  | 'cta-14'
  | 'cta-GT-18'
  | 'cta-cookies-11'
  | 'cta-order-13'
  | 'inherit'
export type InlineCtaThemes = LineThemes

export interface BaseProps extends DOMAttributes<any> {
  className?: string
  childrenClassName?: string
  lineClassName?: string
  href?: string | UrlObject
  children?: ReactNode
  theme?: InlineCtaThemes
  linkType?: 'Web' | 'Document' | 'Media'
  forceUnderline?: boolean
  withUnderLine?: boolean
  textPreset?: InlineCtaTextPresets
  lineProps?: LineProps
  disabled?: boolean
  innerRef?: any
  asDiv?: boolean
}

export type InlineCtaProps = BaseProps | (LinkProps & BaseProps)

function InlineCta({
  className,
  childrenClassName,
  children,
  innerRef,
  href,
  theme,
  onMouseEnter,
  onMouseLeave,
  forceUnderline,
  withUnderLine,
  textPreset,
  lineProps,
  linkType,
  asDiv,
  ...rest
}: InlineCtaProps) {
  const ctaTextStyle = useStyle({
    textPreset,
    color: theme,
  })

  const isExternal = linkType !== 'Document'

  const classNames = cx(className, css.InlineCta, ctaTextStyle)

  const { shouldUnderline, ...underlineMouseEvents } = useUnderline({
    onMouseEnter,
    onMouseLeave,
    forceUnderline,
  })

  const { className: lineClassName, ...restLineProps } = lineProps
  const innerContent = (
    <span className={cx(childrenClassName, css.children)}>
      {children}
      {withUnderLine && (
        <Line
          className={cx(lineClassName, css.line)}
          isVisible={shouldUnderline}
          {...restLineProps}
        />
      )}
    </span>
  )

  const props = {
    ref: innerRef,
    className: classNames,
    ...rest,
    ...underlineMouseEvents,
  }

  return href ? (
    <Link href={href} isExternal={isExternal} {...props}>
      {innerContent}
    </Link>
  ) : asDiv ? (
    <div {...props}>{innerContent}</div>
  ) : (
    <button {...props}>{innerContent}</button>
  )
}

InlineCta.defaultProps = {
  theme: 'black',
  textPreset: 'cta-10',
  lineProps: {
    acumulator: 1,
    initialLineState: 'underlined',
  },
  linkType: 'Web',
  withUnderLine: true,
}

export default forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  InlineCtaProps
>((props, ref) => <InlineCta innerRef={ref} {...props} />)
