import dynamic from 'next/dynamic'

import type { DownshiftSelectProps } from '~/components/Form/DownshiftSelect'
import DownshiftSelectMock from '~/components/Form/DownshiftSelectMock'
import MockSwapper from '~/components/MockSwapper'

export type { Option } from '~/components/Form/DownshiftSelectMock'

const DownshiftSelect = dynamic(
  () => import('~/components/Form/DownshiftSelect'),
)

function DropdownSelect({
  className,
  productSelect,
  langSelect,
  dateSelect,
  accountSelect,
  currentValue,
  textPreset,
  ...selectProps
}: DownshiftSelectProps) {
  const defaultProps = {
    className,
    productSelect,
    langSelect,
    dateSelect,
    accountSelect,
    currentValue,
    textPreset,
  }

  return (
    <MockSwapper
      className={className}
      RealComponent={DownshiftSelect}
      MockComponent={DownshiftSelectMock}
      realProps={{
        ...defaultProps,
        ...selectProps,
      }}
      mockProps={defaultProps}
    />
  )
}

export default DropdownSelect
