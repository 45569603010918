import { useRouter } from "next/router"
import { useEffect } from "react"

export default function useCursorLoading () {
  const router = useRouter()

  useEffect(() => {
    const body = document?.querySelector('body')
    const onLoading = (url) => body.classList.add('loading')
    const onFinished = (url) => body.classList.remove('loading')

    router.events.on('routeChangeStart', onLoading)
    router.events.on('routeChangeComplete', onFinished)
    router.events.on('routeChangeError', onFinished)

    return () => {
      router.events.off('routeChangeStart', onLoading)
      router.events.off('routeChangeComplete', onFinished)
      router.events.off('routeChangeError', onFinished)
    }
  }, [])
}