import classnames from 'classnames/bind'
import { AnimatePresence } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'

import { useSetStyle, useTimeline } from '@unlikelystudio/react-gsap-hooks'
import {
  useIsomorphicLayoutEffect,
  useSpring,
  useWindowSize,
} from '@unlikelystudio/react-hooks'

import { usePanel } from '~/providers/PanelProvider'

import useClickOutside from '~/hooks/useClickOutside'

import css from './styles.module.scss'
import { PanelManagerProps } from './types'

const cx = classnames.bind(css)

function PanelManager({ className }: PanelManagerProps) {
  const { panels, removeCurrent } = usePanel()
  const backgroundRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const childrenRef = useRef<HTMLDivElement>(null)
  const managerRef = useRef<HTMLDivElement>(null)
  const [direction, setDirection] = useState(null)

  const currentItem = panels?.length > 0 && panels?.[panels?.length - 1]

  const setContentStyle = useSetStyle(contentRef)
  const setChildrenStyle = useSetStyle(childrenRef)

  const onEscDown = (e) => {
    if (e.keyCode == 27) removeCurrent()
  }
  useEffect(() => {
    if (currentItem?.direction) setDirection(currentItem.direction)
  }, [currentItem])

  useEffect(() => {
    window.addEventListener('keydown', onEscDown)

    return () => {
      window.removeEventListener('keydown', onEscDown)
    }
  }, [])

  const tl = useTimeline(
    {
      paused: true,
      restoreTime: true,
    },
    (tl) => {
      tl.fromTo(
        backgroundRef.current,
        { autoAlpha: 0 },
        {
          autoAlpha: 1,
          duration: 0.25,
        },
        '0.25',
      )

      tl.to(
        contentRef.current,
        {
          opacity: 1,
          duration: 0.25,
          ease: 'linear',
        },
        '0.35',
      )
    },
    [],
  )

  const { width } = useWindowSize()

  const [setProgressSpring] = useSpring({
    config: {
      interpolation: 'basic',
      friction: 10 + width * 0.003,
      precisionStop: 0.000001,
    },
    progress: 0,
    onUpdate: ({ progress }, { progress: prevProgress }) => {
      const dir = direction === 'left' ? -1 : 1
      if (progress !== prevProgress) {
        const x = 100 - progress * 100

        setContentStyle({ x: `${x * dir}%`, force3D: true })
        setChildrenStyle({ x: `${-x * dir}%`, force3D: true })
      }
    },
  })

  useIsomorphicLayoutEffect(() => {
    setProgressSpring({ progress: currentItem ? 1 : 0 })
    if (currentItem) tl.play()
    else tl.reverse()
  }, [currentItem])

  useClickOutside(
    managerRef,
    () => {
      currentItem?.component && currentItem.component && removeCurrent()
    },
    [currentItem],
  )

  const onClose = () => {
    removeCurrent()
  }

  return (
    <section className={cx(className, css.PanelManager)} ref={managerRef}>
      <div
        className={cx(css.container, {
          show: currentItem,
          interactive: currentItem,
          enterFromLeft: direction === 'left',
        })}>
        <div className={css.background} ref={backgroundRef} onClick={onClose} />
        <div className={css.content} ref={contentRef}>
          <div className={css.children} ref={childrenRef}>
            <AnimatePresence>
              {currentItem?.component && currentItem.component}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </section>
  )
}

PanelManager.defaultProps = {}

export default PanelManager
