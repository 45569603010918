import Cookies from 'js-cookie'
import { COOKIE_KEYS } from '~/lib/constants'

import useCurrency from '~/hooks/useCurrency'

export default function useWishlistId() {
  const currency = useCurrency()
  const wishlistId = Cookies.get(`${COOKIE_KEYS.wishlistId}-${currency}`)
  return wishlistId
}
