export default {
  Hero: [],
  GridItemBig: [
    { breakpoint: 'xxl', columns: 18 },
    { breakpoint: 'lg', columns: 12 },
    { columns: 24 },
  ],
  GridItemSmall: [
    { breakpoint: 'xxl', columns: 10 },
    { breakpoint: 'lg', columns: 8 },
    { columns: 24 },
  ],
  GridThreeItems: [
    { breakpoint: 'md', columns: 8 },
    { columns: 22 },
  ],
  MidScreen: [
    { breakpoint: 'xxl', columns: 14 },
    { breakpoint: 'md', columns: 12 },
    { columns: 24 },
  ]
}