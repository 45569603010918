import classnames from 'classnames/bind'
import React from 'react'

import Image from '~/components/Image'
import Link from '~/components/Link'
import { NavigationItem } from '~/components/Navigation/types'

import css from './styles.module.scss'

const cx = classnames.bind(css)

interface NavigationLogoProps extends NavigationItem {
  className?: string
}

function NavigationLogo({ className, image, link }: NavigationLogoProps) {
  return (
    <div className={cx(className, css.NavigationLogo)}>
      {link && (
        <Link
          className={css.logo}
          prefetch={false}
          aria-label="Back to homepage page"
          {...link}>
          {image && (
            <Image
              {...image}
              layout="fill"
              objectFit="contain"
              priority={true}
              sizesFromBreakpoints={[{ columns: 2 }]}
              alt={image?.alt ?? 'JEM logo'}
            />
          )}
        </Link>
      )}
    </div>
  )
}

export default NavigationLogo
