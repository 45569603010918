import classnames from 'classnames/bind'
import React, { forwardRef, ReactChild } from 'react'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface BottomButtonsProps {
  className?: string
  children: ReactChild | ReactChild[]
  innerRef?: any
}

function BottomButtons({ className, children, innerRef }: BottomButtonsProps) {
  return (
    <section ref={innerRef} className={cx(className, css.BottomButtons)}>
      {children}
    </section>
  )
}

BottomButtons.defaultProps = {}

export default forwardRef<HTMLElement, BottomButtonsProps>((props, ref) => (
  <BottomButtons innerRef={ref} {...props} />
))
