import Cookies from 'js-cookie'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { useStableVh } from '@unlikelystudio/react-hooks'

import BrokenPagePlaceholder from '~/components/BrokenPagePlaceholder'
import LoadingComponent from '~/components/LoadingComponent'
import Page from '~/components/Page'
import ThirdPartyScripts from '~/components/ThirdPartyScripts'

import AppProviders from '~/providers/AppProviders'

import useCursorLoading from '~/hooks/useCursorLoading'
import useLocale from '~/hooks/useLocale'

import '~/styles/globals.scss'

function MyApp({ Component, pageProps }: AppProps) {
  const { asPath, pathname, isFallback } = useRouter()
  const locale = useLocale()
  const key = `${asPath.split('?')?.[0]}${locale}`
  const hasData = Object.keys(pageProps)?.length !== 0

  useEffect(() => {
    // Disable setting cookie when coming from redirect routes to get the last one filled
    if (pathname !== '/redirects/[uid]') {
      Cookies.set('NEXT_LOCALE', locale, {
        expires: 1,
      })
    }
  }, [locale])

  useCursorLoading()
  useStableVh()

  return (
    <AppProviders pageProps={pageProps}>
      {isFallback ? (
        <LoadingComponent />
      ) : hasData ? (
        <>
          <Page {...pageProps} key={key}>
            <Component {...pageProps} />
          </Page>
          <ThirdPartyScripts />
        </>
      ) : (
        <BrokenPagePlaceholder />
      )}
    </AppProviders>
  )
}

export default MyApp
