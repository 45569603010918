import classnames from 'classnames/bind'
import React from 'react'

import InlineCta from '~/components/InlineCta'
import Link from '~/components/Link'
import NavigationLink from '~/components/Navigation/common/NavigationLink'
import { NavigationRowLinkCallbacks } from '~/components/Navigation/common/NavigationRowLink'
import { NavigationDataColumn } from '~/components/Navigation/types'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface MobilePanelSectionProps
  extends NavigationDataColumn,
    NavigationRowLinkCallbacks {
  className?: string
  toggleMenu: () => void
}

export default function MobilePanelSection({
  className,
  toggleMenu,
  title,
  bottomLink,
  links,
}: MobilePanelSectionProps) {
  const titleStyle = useStyle({ textPreset: 'cta-13' })
  const linkStyle = useStyle({ textPreset: 'cta-GT-18' })
  const bottomLinkStyle = useStyle({ color: 'gold' })

  return (
    <div className={cx(className, css.Row)}>
      {title && title?.children && (
        <InlineCta className={cx(titleStyle, css.title)} {...title} />
      )}
      <div className={css.links}>
        {links
          ?.filter?.((item) => item?.link && item?.link?.children)
          ?.map(({ link }, index) => (
            <Link
              key={`navigationRowLink${link?.href}${index}`}
              className={cx(linkStyle, css.link)}
              onClick={toggleMenu}
              {...link}
            />
          ))}
        {bottomLink?.children && (
          <NavigationLink
            className={cx(css.bottomLink, bottomLinkStyle)}
            handleClick={toggleMenu}
            link={bottomLink}
            inlineCtaProps={{
              lineProps: {
                initialLineState: 'underlined',
                theme: 'gold',
              },
            }}
          />
        )}
      </div>
    </div>
  )
}

MobilePanelSection.defaultProps = {}
