import classnames from 'classnames/bind'
import { AnimatePresence, usePresence } from 'framer-motion'
import React, { ReactNode, useRef, useState } from 'react'

import { usePanel } from '~/providers/PanelProvider'

import useBodyScrollLock from '~/hooks/useBodyScrollLock'
import useClickOutside from '~/hooks/useClickOutside'

import { ReactComponent as CloseIcon } from './close.svg'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface PanelWrapperProps {
  className?: string
  enterFromLeft?: boolean
  withoutBorder?: boolean
  withoutCloseButton?: boolean
  onHide?: () => void
  children: ReactNode | ReactNode[]
  verticalAlignCenter?: boolean
}

function PanelWrapper({
  className,
  enterFromLeft,
  withoutBorder,
  withoutCloseButton,
  children,
  verticalAlignCenter,
  onHide,
}: PanelWrapperProps) {
  const { removeCurrent } = usePanel()
  const [isPresent] = usePresence()
  const componentRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const childrenContentRef = useRef<HTMLDivElement>(null)
  const [hide, setHide] = useState(false)

  useClickOutside(contentRef, () => {
    isPresent && removeCurrent()
    setHide(true)
    onHide && onHide()
  })

  useBodyScrollLock(isPresent, childrenContentRef?.current, 'panel')

  return (
    <AnimatePresence>
      <div
        ref={componentRef}
        className={cx(className, css.PanelWrapper, { enterFromLeft })}>
        <div ref={contentRef} className={css.content}>
          {!withoutCloseButton && (
            <div className={css.close} onClick={removeCurrent}>
              <CloseIcon />
            </div>
          )}
          <div className={cx(css.childrenWrapper, { withoutBorder })}>
            <div ref={childrenContentRef} className={css.childrenContent}>
              <div className={cx(css.children, { verticalAlignCenter })}>
                {typeof children === 'function' ? children({ hide }) : children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AnimatePresence>
  )
}

PanelWrapper.defaultProps = {
  enterFromLeft: false,
}

export default PanelWrapper
