import Head from 'next/head'
import React, {
  useContext,
  createContext,
  ReactNode,
  useState,
  useRef,
  useEffect,
} from 'react'

declare global {
  interface Window {
    Cookiebot: any
  }
}

interface CookiesContext {
  openBanner?: boolean
  acceptAllTerms?: any
  consent?: any
  setOpenBanner?: any
}

interface CookiesProviderProps {
  children?: ReactNode | ReactNode[]
}

export const Context = createContext<CookiesContext>({})

export function useCookiesContext() {
  return useContext(Context)
}

export default function CookiesProvider({ children }: CookiesProviderProps) {
  const [openBanner, setOpenBanner] = useState(false)
  const cookieBotManager = useRef(null)
  const [consent, setConsent] = useState(null)

  useEffect(() => {
    bindEvents()
    return () => {
      removeEvents()
    }
  }, [])

  useEffect(() => {
    if (cookieBotManager?.current) {
      setOpenBanner(
        cookieBotManager?.current?.consented ||
          cookieBotManager?.current?.declined
          ? false
          : true,
      )
    }
  }, [consent])

  const bindEvents = (): void => {
    // Cookie already consented or declined
    window.addEventListener('CookiebotOnConsentReady', init)
    // Cookie no setup
    window.addEventListener('CookiebotOnDialogInit', init)
    // All events
    window.addEventListener('CookiebotOnAccept', update)
  }

  const removeEvents = (): void => {
    window.removeEventListener('CookiebotOnConsentReady', init)
    window.removeEventListener('CookiebotOnDialogInit', init)
    window.removeEventListener('CookiebotOnAccept', update)
  }

  const init = () => {
    if (cookieBotManager.current) return
    cookieBotManager.current = window.Cookiebot
    const params = cookieBotManager?.current?.consented
      ? cookieBotManager?.current?.consent
      : cookieBotManager?.current?.dialog?.prechecked
    setConsent(params)
  }

  const update = () => {
    if (
      cookieBotManager?.current?.consent === consent &&
      cookieBotManager?.current
    )
      return setOpenBanner(
        cookieBotManager?.current?.consented ||
          cookieBotManager?.current?.declined
          ? false
          : true,
      )
    setConsent(cookieBotManager?.current?.consent)
  }

  const acceptAllTerms = (
    optinPreferences: boolean,
    optinStatistics: boolean,
    optinMarketing: boolean,
  ) => {
    cookieBotManager?.current?.submitCustomConsent(
      optinPreferences,
      optinStatistics,
      optinMarketing,
    )
    cookieBotManager?.current?.hide()
    cookieBotManager?.current?.runScripts()
    update()
  }

  const providerValue = {
    openBanner,
    setOpenBanner,
    acceptAllTerms,
    consent,
  }

  return (
    <Context.Provider value={providerValue}>
      <Head>
        <script
          defer
          async
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid={`${process.env.NEXT_PUBLIC_COOKIEBOT_ID}`}
          data-blockingmode="auto"
          type="text/javascript"></script>
      </Head>
      {children}
    </Context.Provider>
  )
}
