import classnames from 'classnames/bind'
import React from 'react'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface SliderControlsProps {
  className?: string
  length?: number
  slideIndex?: number
  setSlideIndex?: (index: number) => void
  withArrows?: boolean
}

export default function SliderControls({
  className,
  length,
  setSlideIndex,
  slideIndex,
}: SliderControlsProps) {
  const indexes = [...Array.from(Array(length).keys())]

  return (
    <div className={cx(css.SliderControls, className)}>
      <div className={css.buttonsWrapper}>
        {indexes?.map((_, index) => (
          <span
            key={`sliderControl${index}`}
            onClick={() => setSlideIndex(index)}
            className={cx(css.button, { isActive: slideIndex === index })}
          />
        ))}
      </div>
    </div>
  )
}
