import classnames from 'classnames/bind'
import { ReactNode } from 'react'

import { ArrowSelectIcon } from '~/components/icons'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface Option {
  label: ReactNode
  value: any
}

export interface DownshiftSelectMockProps {
  className?: string
  productSelect?: boolean
  langSelect?: boolean
  dateSelect?: boolean
  accountSelect?: boolean
  textPreset?: string
  currentValue?: Option
}

function DownshiftSelectMock({
  className,
  productSelect,
  langSelect,
  dateSelect,
  accountSelect,
  textPreset,
  currentValue,
}) {
  const selectTextStyle = useStyle({
    textPreset: textPreset ? textPreset : 'input-select-10',
    color: 'gray-20',
  })

  return (
    <div
      className={cx(className, css.DownshiftSelect, {
        productSelect,
        langSelect,
        dateSelect,
        accountSelect,
      })}>
      <button className={cx(selectTextStyle, css.button)} type="button">
        <span>{currentValue?.label}</span>
        <ArrowSelectIcon className={css.icon} />
      </button>
    </div>
  )
}

export default DownshiftSelectMock
