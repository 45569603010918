import { ReactNode } from 'react'
import { TRACKING_EVENTS, WISHLIST_VARIATION_KEY } from '~/lib/constants'

import { PanelLogin } from '~/components/Panels'

import { usePanel } from '~/providers/PanelProvider'
import { useTracking } from '~/providers/TrackingProvider'
import { useWishlist } from '~/providers/WishlistProvider'

import useAddToWishlist from '~/hooks/account/useAddToWishlist'
import useRemoveFromWishlist from '~/hooks/account/useRemoveFromWishlist'

import { ProductTrackingData } from '~/data/serialize-product-tracking-data'

export interface WishlistManagerProps {
  id?: string
  ids?: string[]
  shopifyId?: string
  shopifyIds?: string[]
  children: (params: WishlistElementProps) => ReactNode
  trackingData?: ProductTrackingData
}

interface WishlistElementProps {
  active?: boolean
  handleClick?: (e: MouseEvent) => void
}

function WishlistManager({
  children,
  id,
  ids,
  shopifyId,
  shopifyIds,
  trackingData,
}: WishlistManagerProps) {
  const { add } = usePanel()
  const { wishlist, isSuccess } = useWishlist()
  const { mutate: addToWishlist, isLoading: isAdding } = useAddToWishlist()
  const { mutate: removeFromWishlist, isLoading: isRemoving } =
    useRemoveFromWishlist()
  const isAssembled = Boolean(shopifyIds)
  const tracking = useTracking()
  const formattedId =
    isAssembled && `${id}__${ids.join('__')}${WISHLIST_VARIATION_KEY}`

  const wishlistProduct = wishlist?.products?.find((product) => {
    return product?.shopify_admin_id === shopifyId
  })

  const wishlistProducts =
    isAssembled &&
    wishlist?.products?.filter(
      (product) =>
        shopifyIds?.includes(product?.shopify_admin_id) &&
        product?.prismic_id === formattedId,
    )

  const productInWishlist = Boolean(
    wishlistProduct || (isAssembled && wishlistProducts?.length > 0),
  )

  const handleClick = (e) => {
    e.preventDefault()

    if (isAdding || isRemoving) return

    if (!wishlist && isSuccess) {
      return add({ component: <PanelLogin redirectOnSuccess={false} /> })
    }

    if (productInWishlist) {
      if (!isAssembled) {
        return removeFromWishlist({
          id: wishlistProduct.wishlistProductId,
        })
      } else {
        return Promise.all(
          wishlistProducts?.map(async (product) =>
            removeFromWishlist({ id: product.wishlistProductId }),
          ),
        )
      }
    } else {
      tracking?.emit(TRACKING_EVENTS.ADD_TO_WISHLIST, trackingData)
      if (!isAssembled) {
        addToWishlist({ id, shopifyId })
      } else {
        const hasSameHalves = shopifyIds?.[0] === shopifyIds?.[1]

        if (hasSameHalves) {
          return addToWishlist({
            id: formattedId,
            shopifyId: shopifyIds?.[0],
          })
        }

        return Promise.all(
          shopifyIds?.map(async (halfId) =>
            addToWishlist({
              id: formattedId,
              shopifyId: halfId,
            }),
          ),
        )
      }
    }
  }

  const iconStatus =
    (productInWishlist && !isRemoving) || (!productInWishlist && isAdding)

  return <>{children({ active: Boolean(iconStatus), handleClick })}</>
}

WishlistManager.defaultProps = {}

export default WishlistManager
