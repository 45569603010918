import { useRouter } from 'next/router'
import { useEffect } from 'react'

export default function useOnRouteChange(
  onRouteChange: (path: string) => void,
) {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = () => {
      onRouteChange(router.asPath)
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])
}
