import { SHOPIFY_API_VERSION } from '~/lib/constants'
import { getMultistoreCredentials } from '~/lib/get-multistore-token'

import { getShopifyClient } from '@unlikelystudio/provider-shopify'
import SimpleQl from '@unlikelystudio/simpleql'

export function getShopifyMultistoreClient(
  locale: string,
  adminAccess?: boolean,
): SimpleQl {
  const currentStoreCredentials = getMultistoreCredentials(locale)

  return currentStoreCredentials
    ? getShopifyClient({
        url: currentStoreCredentials?.url ?? null,
        version: SHOPIFY_API_VERSION ?? null,
        storefrontAccessToken:
          currentStoreCredentials?.storeFrontAccessToken ?? null,
        ...(adminAccess && {
          adminAccessToken: currentStoreCredentials?.adminAccessToken ?? null,
        }),
      })
    : null
}
