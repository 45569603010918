import {
  DEFAULT_UNIQUE_ID,
  ENGRAVING_MESSAGE_KEY,
  ENGRAVING_TYPE_KEY,
  HREF_KEY,
  IMAGE_KEY,
  PAIRED_KEY,
  PRODUCT_VARIATION_ATTRIBUTE_KEY,
  TRACKING_DATA,
  WITH_ENGRAVING_KEY,
} from '~/lib/constants'
import linkResolver from '~/lib/link-resolver'

import { Checkout } from '@unlikelystudio/ecommerce-typed/types/shopify'
import { CheckoutLineItemEdge } from '@unlikelystudio/ecommerce-typed/types/shopify'

import { CartProduct, CheckoutProps } from '~/components/Panels/PanelCart/types'

import { CheckoutProductUidsCookie } from '~/hooks/checkout/useCheckoutProductUids'

import { customAttributesAsObject } from '~/utils/custom-attributes'

import serializePrice from '~/data/serialize-price'

export function serializeCartProducts(
  products: CheckoutLineItemEdge[],
  locale: string,
  uids: CheckoutProductUidsCookie,
): CartProduct[] {
  return products?.map(({ node }) => {
    const cookieData =
      uids?.[locale]?.find(({ id }) => id === node?.variant?.product?.id) ??
      null

    const formattedCustomAttributes = customAttributesAsObject(
      node?.customAttributes ?? [],
    )

    const withEngraving = Boolean(
      formattedCustomAttributes?.[WITH_ENGRAVING_KEY],
    )

    const isPaired = Boolean(formattedCustomAttributes?.[PAIRED_KEY])

    const variationAttribute =
      formattedCustomAttributes?.[PRODUCT_VARIATION_ATTRIBUTE_KEY] ?? null
    const hrefAttribute = formattedCustomAttributes?.[HREF_KEY] ?? null
    const imageAttribute = formattedCustomAttributes?.[IMAGE_KEY] ?? null

    return {
      id: node?.id,
      href: hrefAttribute,
      isPaired,
      trackingData: formattedCustomAttributes?.[TRACKING_DATA]
        ? {
            ...JSON.parse(formattedCustomAttributes?.[TRACKING_DATA]),
            quantity: node?.quantity,
          }
        : null,
      uniqueId: formattedCustomAttributes?.[DEFAULT_UNIQUE_ID],
      variantId: node?.variant?.id,
      price: serializePrice(
        locale,
        node?.variant?.priceV2?.currencyCode,
        node?.variant?.priceV2?.amount,
      ),
      priceAmount: node?.variant?.priceV2?.amount ?? null,
      title: node?.title,
      size: node?.variant?.title,
      quantity: node?.quantity,
      imageSrc: node?.variant?.image?.transformedSrc ?? imageAttribute ?? null,
      ...(cookieData && {
        href: linkResolver({
          type: 'product',
          lang: locale,
          uid: cookieData?.uid,
        }),
      }),
      ...(variationAttribute && {
        variationAttribute,
      }),
      ...(withEngraving && {
        engraving: {
          [ENGRAVING_TYPE_KEY]:
            formattedCustomAttributes[`_${ENGRAVING_TYPE_KEY}`],
          [ENGRAVING_MESSAGE_KEY]:
            formattedCustomAttributes[`_${ENGRAVING_MESSAGE_KEY}`],
        },
      }),
    }
  })
}

export default function serializeCheckout(
  checkout: Checkout,
  uids: CheckoutProductUidsCookie,
  locale: string,
): CheckoutProps {
  const customAttributes = customAttributesAsObject(
    checkout?.customAttributes ?? [],
  )

  return {
    id: checkout?.id ?? null,
    customAttributes,
    completed: checkout?.completedAt ? true : false,
    total: checkout
      ? serializePrice(
          locale,
          checkout?.totalPriceV2?.currencyCode,
          checkout?.totalPriceV2?.amount,
        )
      : null,
    subtotal: checkout
      ? serializePrice(
          locale,
          checkout?.subtotalPriceV2?.currencyCode,
          checkout?.subtotalPriceV2?.amount,
        )
      : null,
    totalTax: checkout
      ? serializePrice(
          locale,
          checkout?.totalTaxV2?.currencyCode,
          checkout?.totalTaxV2?.amount,
        )
      : null,
    webUrl: checkout?.webUrl ?? null,
    productsQuantity: checkout?.lineItems?.edges?.reduce?.(
      (previousValue, lineItem) => {
        return previousValue + lineItem?.node?.quantity ?? 0
      },
      0,
    ),
    products: serializeCartProducts(checkout?.lineItems?.edges, locale, uids),
  }
}
