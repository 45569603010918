import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'

import { AlertProps } from '~/components/Alert'

export const Context = createContext({
  alerts: [],
  triggerAlert: (newAlert: AlertProps) => {},
  removeAlert: (alertUid: number) => {},
})

export function useAlertContext() {
  return useContext(Context)
}

export interface AlertProviderProps {
  children?: ReactNode | ReactNode[]
}

function AlertProvider({ children }: AlertProviderProps) {
  const alertUid = useRef(0)
  const [alerts, setAlert] = useState<AlertProps[]>([])
  const triggerAlert = useCallback(
    (newAlert: AlertProps) => {
      setAlert((prevAlerts) => {
        alertUid.current = alertUid.current + 1
        return [...prevAlerts, { ...newAlert, uid: alertUid.current }]
      })
    },
    [setAlert],
  )

  const removeAlert = useCallback(
    (alertUid) => {
      setAlert((prevAlerts) => {
        return prevAlerts?.filter((alert) => alert.uid !== alertUid)
      })
    },
    [setAlert],
  )

  const providerValue = useMemo(
    () => ({
      alerts,
      triggerAlert,
      removeAlert,
    }),
    [alerts, setAlert, triggerAlert, removeAlert],
  )

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}

AlertProvider.defaultProps = {}

export default AlertProvider
