import classnames from 'classnames/bind'
import React, { useRef, useContext, ReactNode, forwardRef } from 'react'

import { useTimeline } from '@unlikelystudio/react-gsap-hooks'
import { useMeasure } from '@unlikelystudio/react-hooks'
import { useIsomorphicLayoutEffect } from '@unlikelystudio/react-hooks'

import { AccordionContext } from '~/components/Accordion/index'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface AccordionPanelProps {
  className?: string
  index?: number
  restoreIndex?: boolean
  head: ReactNode
  body?: ReactNode | ReactNode[]
  theme?: 'light' | 'dark'
  customHandleClick?: (index: number) => void
}

const AccordionPanel = (
  {
    className,
    index,
    restoreIndex,
    head,
    body,
    customHandleClick,
  }: AccordionPanelProps,
  ref: any,
) => {
  const { currentIndex, setCurrentIndex } = useContext(AccordionContext)
  const bodyRef = useRef()
  const indexRef = useRef(null)
  const { height } = useMeasure(bodyRef)
  const isActive = index === currentIndex

  const tl = useTimeline(
    { paused: true, restoreTime: true },
    (tl) => {
      if (bodyRef.current) {
        tl.fromTo(
          bodyRef.current,
          { height: 0 },
          {
            height: 'auto',
            duration: 0.7,
            ease: 'power3.inOut',
          },
        )
      }
    },
    [height],
  )

  const handleClick = (index: number) => {
    if (customHandleClick) {
      customHandleClick(index)
      return
    }

    const newIndex = index === currentIndex ? null : index
    indexRef.current = newIndex
    setCurrentIndex(newIndex)
  }
  useIsomorphicLayoutEffect(() => {
    if (index === currentIndex) tl.play()
    else tl.reverse()
  }, [currentIndex])

  useIsomorphicLayoutEffect(() => {
    if (restoreIndex) setCurrentIndex(null)
  }, [restoreIndex])

  return (
    <div ref={ref} className={cx(className, css.AccordionPanel)}>
      <button
        className={css.head}
        aria-label="dropdown"
        aria-expanded={currentIndex === index}
        onClick={() => handleClick(index)}>
        {typeof head === 'function' ? head(isActive) : head}
      </button>
      {body && (
        <div ref={bodyRef} className={css.body}>
          {body}
        </div>
      )}
    </div>
  )
}

const AccordionPanelWithRef = forwardRef(AccordionPanel)

export default AccordionPanelWithRef
