import React, { useContext, createContext, useState, ReactNode } from 'react'

export const ConfiguratorContext = createContext<ValueType>({})

interface ConfiguratorProviderProps {
  children: ReactNode | ReactNode[]
}

export type ConfiguratorType = Record<string, any>

export interface ValueType {
  configuratorIsVisible?: boolean
  setConfiguratorIsVisible?: (state: boolean) => void
}

export default function ConfiguratorProvider({
  children,
}: ConfiguratorProviderProps) {
  const [configuratorIsVisible, setConfiguratorIsVisible] =
    useState<boolean>(false)

  return (
    <ConfiguratorContext.Provider
      value={{ configuratorIsVisible, setConfiguratorIsVisible }}>
      {children}
    </ConfiguratorContext.Provider>
  )
}

export function useConfigurator() {
  return useContext(ConfiguratorContext)
}
