import Cookies from 'js-cookie'
import { useQuery, UseQueryOptions } from 'react-query'
import { COOKIE_KEYS } from '~/lib/constants'
import { getWishlist } from '~/lib/handle-api'
import { getAccessToken } from '~/lib/token'

import { IGraphQLResponse } from '@unlikelystudio/simpleql'

import useGetWishlistQueryKey from '~/hooks/account/useGetWishlistQueryKey'
import useCurrency from '~/hooks/useCurrency'

export interface WishlistProduct {
  id: string
  wishlistProductId?: string
  shop_id: string
  customer_id: string
  wishlist_id: string
  name?: string
  prismic_id: string
  shopify_admin_id: string
  created_at?: string
  updated_at?: string
  variants?: any[]
  rootProductId?: string
}
export interface Wishlist {
  created_at: string
  customer_id: string
  id: string
  isPublic: boolean
  products: WishlistProduct[]
  public_id: string
  shop_id: string
  title: string
  updated_at: string
}

export type GetWishlistMutationResponse = IGraphQLResponse<Wishlist>

export default function useGetWishlist(
  options?: UseQueryOptions<any, any, any>,
) {
  const wishlistQueryKeys = useGetWishlistQueryKey()
  const currency = useCurrency()

  return useQuery<Wishlist>(
    wishlistQueryKeys,
    async () => {
      const accessToken = getAccessToken(currency)

      if (!accessToken) return null

      const wishlist = await getWishlist({
        store: currency,
        accessToken,
      })

      return {
        ...wishlist,
        products: wishlist?.products?.map((product) => {
          return {
            ...product,
            wishlistProductId: product.id,
          }
        }),
      }
    },
    {
      ...options,
      onSuccess: (wishlist) => {
        const wishlistId = wishlist?.id
        Cookies.set(`${COOKIE_KEYS.wishlistId}-${currency}`, wishlistId)
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 300000,
    },
  )
}
