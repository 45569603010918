import classnames from 'classnames/bind'
import React, { useRef } from 'react'

import Slider from '@unlikelystudio/react-slider'

import { SocialProps } from '~/components/Footer/FooterContact'
import NewsletterInput from '~/components/Form/Newsletter'
import Image, { ImageProps } from '~/components/Image'
import ImageWithPlaceholder from '~/components/ImageWithPlaceholder'
import Link, { LinkProps } from '~/components/Link'
import Ratio from '~/components/Ratio'
import RichText, { RichTextBlock } from '~/components/RichText'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

interface NewsletterImageProps {
  className?: string
  link?: LinkProps
  image?: ImageProps
}
export interface NewsletterProps {
  className?: string
  title?: RichTextBlock[]
  text?: RichTextBlock[]
  topImage?: NewsletterImageProps
  leftImage?: NewsletterImageProps
  rightImage?: NewsletterImageProps
  socials?: {
    title?: string
    items?: SocialProps[]
  }
}

const DEFAULT_ALT = 'Newsletter link image'

const NewsletterLinkImage = ({
  className,
  link,
  image,
  ...imageProps
}: NewsletterImageProps) => {
  const href = link?.href as string
  return (
    <div className={className}>
      <Link aria-label={href?.split('.')?.[1]} {...link}>
        <Ratio ratio={1}>
          {(cn) => (
            <ImageWithPlaceholder
              className={cn}
              layout="fill"
              objectFit="cover"
              sizesFromBreakpoints={[
                { breakpoint: 'md', columns: 4 },
                { columns: 4 },
              ]}
              {...image}
              {...imageProps}
              alt={image?.alt ?? DEFAULT_ALT}
            />
          )}
        </Ratio>
      </Link>
    </div>
  )
}

function Newsletter({
  className,
  title,
  text,
  topImage,
  leftImage,
  rightImage,
  socials,
}: NewsletterProps) {
  const gridStyle = useStyle({ grid: 'base-grid' })
  const titleStyle = useStyle({ textPreset: 'title-18-13' })
  const textStyle = useStyle({ textPreset: 'text-12-14' })
  const socialsStyle = useStyle({ textPreset: 'title-10' })
  const sliderContainerRef = useRef<HTMLDivElement>(null)

  return (
    <section className={cx(className, css.Newsletter)}>
      <div className={cx(css.grid, gridStyle)}>
        <div ref={sliderContainerRef} className={css.sliderContainer} />
        <div className={css.images}>
          <NewsletterLinkImage
            className={css.topImage}
            link={topImage?.link}
            image={topImage?.image}
          />
          <NewsletterLinkImage
            className={css.leftImage}
            link={leftImage?.link}
            image={leftImage?.image}
          />
          <NewsletterLinkImage
            className={css.rightImage}
            link={rightImage?.link}
            image={rightImage?.image}
          />
        </div>
        <div className={css.content}>
          {title && (
            <RichText className={cx(titleStyle, css.title)} render={title} />
          )}
          {text && (
            <RichText className={cx(textStyle, css.text)} render={text} />
          )}
          <NewsletterInput />
          <div className={css.socials}>
            {socials?.title && (
              <p className={cx(socialsStyle, css.socialsTitle)}>
                {socials.title}
              </p>
            )}
            <Slider
              className={css.slider}
              infinite
              customSliderRef={sliderContainerRef}
              snap>
              {[topImage, leftImage, rightImage].map((item, index) => (
                <NewsletterLinkImage
                  key={`newsletterSliderImage${index}`}
                  className={css.item}
                  link={item?.link}
                  image={item?.image}
                />
              ))}
            </Slider>
            <div className={css.icons}>
              {socials?.items?.map((social, index: number) => {
                const href = social?.link?.href as string
                const platform = href?.split('.')?.[1]
                return (
                  <Link
                    key={`social-${index}`}
                    className={css.icon}
                    aria-label={platform}
                    {...social?.link}>
                    <Image
                      {...social?.icon}
                      alt={
                        social?.icon?.alt ?? platform ?? 'Social platform logo'
                      }
                      sizesFromBreakpoints={[{ columns: 0.5 }]}
                    />
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

Newsletter.defaultProps = {}

export default Newsletter
