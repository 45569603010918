import { RichTextBlock } from '~/components/RichText'
import Slice404 from '~/components/Slice404'

import useLocale from '~/hooks/useLocale'

function BrokenPagePlaceholder() {
  const locale = useLocale()

  const messages = {
    fr: {
      title: 'Nous sommes désolés, une erreur est survenue.',
      cta: "Retour à l'accueil",
    },
    en: {
      title: 'We are sorry, an error occured.',
      cta: 'Back to home',
    },
  }

  const title = [
    {
      type: 'paragraph',
      text: messages[locale].title,
      spans: [],
    },
  ]

  return (
    <Slice404
      title={title as RichTextBlock[]}
      image={{ src: './images/error-image.jpeg' }}
      cta={{ href: '/', children: messages[locale].cta }}
    />
  )
}

BrokenPagePlaceholder.defaultProps = {}

export default BrokenPagePlaceholder
