import React, { useContext, createContext, useState, ReactNode } from 'react'

export const CssVariablesContext = createContext<ValueType>({})

interface CssVariablesProviderProps {
  value: object
  children: ReactNode | ReactNode[]
}

export type VariablesType = Record<string, any>

export interface ValueType {
  variables?: VariablesType
  update?: (id: string, value: any) => void
}

export default function CssVariablesProvider({
  value,
  children,
}: CssVariablesProviderProps) {
  const [variables, setVariables] = useState({})

  const update = (id, val) => {
    setVariables((variables) => ({ ...variables, [id]: val }))
  }

  const processedValue: ValueType = {
    variables: { ...value, ...variables },
    update,
  }

  return (
    <CssVariablesContext.Provider value={processedValue}>
      {children}
    </CssVariablesContext.Provider>
  )
}

export function useCssVariables() {
  return useContext(CssVariablesContext)
}
