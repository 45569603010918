import { useRef } from 'react'

import {
  useIsomorphicLayoutEffect,
  useOnResize,
} from '@unlikelystudio/react-hooks'

import { useCssVariables } from '~/providers/CssVariablesProvider'

export default function use100vh() {
  const root = useRef(null)
  const innerHeight = useRef(null)
  const { update } = useCssVariables()

  useIsomorphicLayoutEffect(() => {
    root.current = document.querySelector('body')
    setHeightProperty()
    window.addEventListener('orientationchange', setHeightProperty)
    return () =>
      window.removeEventListener('orientationchange', setHeightProperty)
  }, [])

  const setHeightProperty = () => {
    innerHeight.current = window.innerHeight
    const current = root.current.style.getPropertyValue('--vh100')
    const newValue = `${innerHeight.current}px`

    if (current !== newValue) update('vh100', newValue)
  }

  useOnResize(setHeightProperty)
}
