import { useEffect, useState } from 'react'

export default function useMockSwap(Component) {
  const [hasClicked, setHasClicked] = useState<boolean>(false)
  const [moduleLoaded, setModuleLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (hasClicked && !moduleLoaded) {
      const listenToDynamicFormRender = async () => {
        //@ts-ignore
        await Component?.render?.preload()
        setModuleLoaded(true)
      }

      listenToDynamicFormRender()
    }
  }, [hasClicked, moduleLoaded, Component?.render])

  return { loaded: hasClicked && moduleLoaded, setSwapMock: setHasClicked }
}
