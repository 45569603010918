import { Elements, HTMLSerializer } from 'prismic-reactjs'
import React, { ReactNode } from 'react'
import { DEFAULT_LOCALE } from '~/lib/constants'

import ImageWithPlaceholder from '~/components/ImageWithPlaceholder'
import { linkResolver } from '~/components/Link'
import Link from '~/components/Link'
import css from '~/components/RichText/styles.module.scss'

import serializeImage from '~/data/serialize-image'

export default function htmlSerializer(
  locale: string,
): HTMLSerializer<ReactNode | null> {
  return (type, element, content, _children) => {
    switch (type) {
      case Elements.hyperlink:
        const href =
          element?.data?.link_type === 'Web'
            ? element?.data?.url
            : linkResolver({ ...element?.data, lang: locale ?? DEFAULT_LOCALE })

        return (
          <Link
            className={css.link}
            href={href}
            key={`rich-text-link-${href}`}
            children={content}
          />
        )
      case Elements.image:
        const image = serializeImage(element)
        return <ImageWithPlaceholder {...image} />
      default:
        // Returning null will render the default PrismicRichText interpretation for the current type
        return null
    }
  }
}
