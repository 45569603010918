import { useQueryClient } from 'react-query'

import { Checkout } from '@unlikelystudio/ecommerce-typed/types/shopify'

import { GetCheckoutMutationResponse } from '~/hooks/checkout/useGetCheckout'
import useGetCheckoutQueryKey from '~/hooks/checkout/useGetCheckoutQueryKey'

export default function useOnCheckoutMutationSuccess(
  mutationResponseNamespace: string,
  filterRemovedLineItems?: boolean,
) {
  const queryClient = useQueryClient()
  const queryKey = useGetCheckoutQueryKey()

  const setNewCheckoutQueryData = (data) => {
    queryClient.setQueryData<GetCheckoutMutationResponse>(
      queryKey,
      (oldData) => {
        const newCheckout = data?.data?.[mutationResponseNamespace]
          ?.checkout as Checkout

        if (newCheckout?.lineItems?.edges) {
          if (filterRemovedLineItems) {
            newCheckout.lineItems.edges = newCheckout.lineItems.edges.filter(
              (lineItem) => {
                return oldData?.data?.node?.lineItems?.edges?.find(
                  (oldLineItem) => oldLineItem?.node?.id === lineItem?.node?.id,
                )
              },
            )
          }
          oldData = {
            ...oldData,
            data: { node: newCheckout },
          }
        }
        return oldData
      },
    )
  }

  return setNewCheckoutQueryData
}
