import classnames from 'classnames/bind'
import Head from 'next/head'
import React, { useState, useRef, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'

import { NavigationData } from '@unlikelystudio/react-abstract-components'
import { useTimeline } from '@unlikelystudio/react-gsap-hooks'
import { useMeasure } from '@unlikelystudio/react-hooks'
import { useIsomorphicLayoutEffect } from '@unlikelystudio/react-hooks'

import Banner from '~/components/Banner'

import { useNavigation } from '~/providers/NavigationProvider'

import useProfile from '~/hooks/account/useGetProfile'

import NavigationDesktop from './Desktop'
import NavigationMobile from './Mobile'
import MobilePanel from './Mobile/MobilePanel'
import css from './styles.module.scss'
import { NavigationProps } from './types'

const cx = classnames.bind(css)

export * from './types'

const Navigation = ({
  banner,
  headerLight,
  withBorder,
  jsonLD,
  ...rest
}: NavigationProps) => {
  const [hasScrolled, setHasScrolled] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const initialRender = useRef(true)
  const headerRef = useRef(null)
  const mobileHeaderRef = useRef(null)
  const backgroundRef = useRef(null)
  const bannerRef = useRef(null)
  const [inViewRef, inView] = useInView({})
  const { height: mobileHeaderHeight } = useMeasure(mobileHeaderRef)
  const { height: bannerHeight } = useMeasure(bannerRef)

  const { navigation } = useNavigation()

  const { isLoading, data } = useProfile(
    {
      refetchOnMount: true,
      keepPreviousData: true,
      retry: 0,
    },
    {
      keys: ['authentication'],
    },
  )

  // Banner + Header Background timeline triggered on scroll
  const tl = useTimeline(
    {
      paused: true,
    },
    (tl) => {
      if (bannerRef.current) {
        tl.fromTo(
          headerRef.current,
          {
            y: 0,
          },
          {
            y: -bannerHeight,
            duration: 0.2,
            ease: 'power3.inOut',
          },
          '<',
        )
      }
    },
    [bannerHeight],
  )

  // Play timeline when required (once the scroll reference div is in/out of view)
  useIsomorphicLayoutEffect(() => {
    if (!inView && !navigation.productHeaderVisible) {
      setHasScrolled(true)
      tl.restart()
    } else {
      setHasScrolled(false)
      tl.reverse()
    }
  }, [inView, navigation])

  useIsomorphicLayoutEffect(() => {
    // Fix first load issue of banner closing up
    if (initialRender.current && window.scrollY === 0) {
      setHasScrolled(false)
    }
  }, [])

  // Set new padding value for mobile navigation panel if the banner is visible or not
  const paddingValue = useMemo(
    () =>
      `body {
        --panel-padding-top: ${115 + (hasScrolled ? 0 : bannerHeight)}px;
        --banner-height: ${bannerHeight}px;
        --mobile-header-height: ${Math.round(mobileHeaderHeight)}px;
      }`,
    [hasScrolled, bannerHeight, mobileHeaderHeight],
  )

  const toggleMenu = () => {
    setIsOpen((prevState) => !prevState)
  }

  return (
    <>
      <Head>{paddingValue && <style>{paddingValue}</style>}</Head>
      {jsonLD?.items?.length > 0 && <NavigationData {...jsonLD} />}
      <div ref={inViewRef} className={css.scrollRef} />
      <header className={css.header}>
        <nav className={css.wrapper}>
          <div
            ref={backgroundRef}
            className={cx(css.background, { hasScrolled, headerLight })}
          />
          <div className={css.navigation} ref={headerRef}>
            {banner?.display && (
              <Banner
                ref={bannerRef}
                className={cx({ hasScrolled })}
                {...banner}
              />
            )}
            <NavigationDesktop
              className={css.largeScreenNavigation}
              hasScrolled={hasScrolled}
              headerLight={headerLight}
              withBorder={withBorder}
              isLogged={!isLoading && data}
              {...rest}
            />
            <NavigationMobile
              ref={mobileHeaderRef}
              hasScrolled={hasScrolled}
              className={css.smallScreenNavigation}
              isOpen={isOpen}
              toggleMenu={toggleMenu}
              isLogged={!isLoading && data}
              {...rest}
            />
          </div>
          <MobilePanel
            className={css.mobilePanel}
            contentClassName={css.mobileContentPanel}
            headerProps={{ ...rest, isOpen, toggleMenu }}>
            {/** The beforePanel div is set to prevent open panel content to overlap header */}
            <div
              className={css.beforePanel}
              style={{
                height: `${
                  mobileHeaderHeight + (hasScrolled ? 0 : bannerHeight)
                }px`,
              }}
            />
          </MobilePanel>
        </nav>
      </header>
    </>
  )
}

export default Navigation
