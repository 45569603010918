import {
  addFilter,
  addFilters,
  removeAll,
  removeFilter,
  sortFilter,
  setDefaultFilters,
  setFacets,
} from './actions'
import { ActionType, FiltersState } from './index'

export interface FiltersReducerPayload {
  [key: string]: any
}

export interface FiltersReducerAction {
  type: ActionType
  payload?: FiltersReducerPayload
}

const FiltersReducer = (state: FiltersState, action: FiltersReducerAction) => {
  switch (action.type) {
    case ActionType.ADD_FILTER:
      return addFilter(state, action)
    case ActionType.ADD_FILTERS:
      return addFilters(state, action)
    case ActionType.REMOVE_FILTER:
      return removeFilter(state, action)
    case ActionType.REMOVE_ALL:
      return removeAll(state)
    case ActionType.SORT_BY:
      return sortFilter(state, action)
    case ActionType.SET_DEFAULT_FILTERS:
      return setDefaultFilters(state, action)
    case ActionType.SET_FACETS:
      return setFacets(state, action)
    default:
      return state
  }
}

export default FiltersReducer
