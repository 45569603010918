import React, { useContext, createContext, useState, ReactNode } from 'react'

export const NavigationContext = createContext<ValueType>({})

interface NavigationProviderProps {
  children: ReactNode | ReactNode[]
}

export type NavigationType = Record<string, any>

export interface ValueType {
  navigation?: NavigationType
  update?: (id: string, value: any) => void
}

export default function NavigationProvider({
  children,
}: NavigationProviderProps) {
  const [navigation, setNavigation] = useState({
    productHeaderVisible: false,
  })

  const update = (id, val) => {
    setNavigation((navigation) => ({ ...navigation, [id]: val }))
  }

  const processedValue: ValueType = {
    navigation,
    update,
  }

  return (
    <NavigationContext.Provider value={processedValue}>
      {children}
    </NavigationContext.Provider>
  )
}

export function useNavigation() {
  return useContext(NavigationContext)
}
