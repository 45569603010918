class GlobalLocale {
  public localeValue: string

  constructor() {
    this.localeValue = null
  }

  get locale() {
    return this.localeValue
  }

  set locale(locale: string) {
    this.localeValue = locale
  }
}

export default new GlobalLocale()
