import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'

import useCurrency from '~/hooks/useCurrency'
import useLocale from '~/hooks/useLocale'

import { getPrismicLangFromLocale } from '~/utils/locales'

export interface CheckoutProductUids {
  id: string
  uid: string
}

export interface CheckoutProductUidsCookie {
  [key: string]: {
    id: string
    uid: string
  }[]
}

export default function useCheckoutProductUids() {
  const [uids, setUids] = useState<CheckoutProductUidsCookie>(null)
  const locale = useLocale()
  const currency = useCurrency()
  const lang = getPrismicLangFromLocale(locale)
  const cookieKey = `checkout-product-uids-${currency}`

  useEffect(() => {
    const json = getCookie()
    setUids(json)
  }, [])

  const addProductUid = ({ id, uid }: CheckoutProductUids) => {
    const json = getCookie()

    if (!json?.[lang]) {
      json[lang] = []
    }

    const entry = json?.[lang]?.find(({ id }) => id === id) ?? null

    if (entry) return

    json?.[lang]?.push({ id, uid })
    Cookies.set(cookieKey, JSON.stringify(json))

    setUids(json)
  }

  const getCookie = (): CheckoutProductUidsCookie => {
    const cookie = Cookies.get(cookieKey) ?? '{}'
    return JSON.parse(cookie)
  }

  return { uids, addProductUid }
}
