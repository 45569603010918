import { prismicRichTextAsText, RichTextBlock } from '~/components/RichText'

export function stringIsNotEmpty(str: string) {
  return (
    str !== '' && str !== null && str !== undefined && typeof str === 'string'
  )
}

export function richTextIsNotEmpty(content: RichTextBlock[]) {
  const str = prismicRichTextAsText(content)
  return str !== '' && content !== null && content !== undefined
}
