import classnames from 'classnames/bind'
import React from 'react'

import InlineCta from '~/components/InlineCta'
import NavigationRowLink, {
  NavigationRowLinkCallbacks,
} from '~/components/Navigation/common/NavigationRowLink'
import { NavigationDataColumn } from '~/components/Navigation/types'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface DesktopPanelRowProps
  extends NavigationDataColumn,
    NavigationRowLinkCallbacks {
  className?: string
  row: number
  hideMenu: () => void
}

export default function DesktopPanelRow({
  className,
  title,
  row,
  bottomLink,
  handleMouseEnter,
  handleMouseLeave,
  links,
  hideMenu,
}: DesktopPanelRowProps) {
  const titleStyle = useStyle({ textPreset: 'title-14' })
  const ctaStyle = useStyle({ color: 'gold' })

  return (
    <div className={cx(className, css.Row)}>
      {title && (
        <InlineCta
          className={cx(titleStyle, css.title, { withHref: title?.href })}
          {...title}
        />
      )}
      <div className={css.links}>
        {links?.map(({ link }, index) => (
          <NavigationRowLink
            key={`navigationRowLink${link?.href}${index}`}
            className={css.link}
            link={link}
            handleMouseEnter={() => handleMouseEnter({ row, item: index })}
            handleMouseLeave={handleMouseLeave}
            onClick={hideMenu}
          />
        ))}
        <InlineCta
          className={cx(ctaStyle, css.bottomLink)}
          lineProps={{
            theme: 'gold',
            initialLineState: 'underlined',
          }}
          {...bottomLink}
          onClick={hideMenu}
        />
      </div>
    </div>
  )
}

DesktopPanelRow.defaultProps = {}
