import classnames from 'classnames/bind'
import React, { MouseEventHandler } from 'react'

import InlineCta, {
  InlineCtaProps,
  InlineCtaTextPresets,
  InlineCtaThemes,
} from '~/components/InlineCta'
import { LinkProps } from '~/components/Link'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface NavigationLinksGroupProps {
  link: LinkProps
  links: LinkProps[]
}

export type NavigationLinkType =
  | 'primary'
  | 'secondary'
  | 'dropdown'
  | 'menuAccordion'

export interface NavigationLinkProps {
  className?: string
  link: LinkProps
  handleMouseEnter?: () => void
  handleClick?: MouseEventHandler<any>
  type?: NavigationLinkType
  theme?: InlineCtaThemes
  forceUnderline?: boolean
  inlineCtaProps?: InlineCtaProps
  linkClassName?: string
}

type NavigationLinkTypes = {
  [key in NavigationLinkType]: InlineCtaTextPresets
}
const navigationLinkTypes: NavigationLinkTypes = {
  primary: 'cta-11',
  secondary: 'cta-10',
  dropdown: 'cta-GT-18',
  menuAccordion: 'cta-12',
}

function NavigationLink({
  className,
  linkClassName,
  forceUnderline,
  link,
  theme,
  handleMouseEnter,
  handleClick,
  type,
  inlineCtaProps,
  ...rest
}: NavigationLinkProps) {
  return (
    <InlineCta
      className={cx(type, css.NavigationLink, linkClassName)}
      childrenClassName={className}
      forceUnderline={forceUnderline}
      lineProps={{
        className: css.line,
        initialLineState: 'none',
      }}
      textPreset={navigationLinkTypes[type]}
      theme={theme}
      onMouseEnter={handleMouseEnter}
      onClick={handleClick}
      {...link}
      {...inlineCtaProps}
      {...rest}
    />
  )
}

NavigationLink.defaultProps = {
  type: 'primary',
  theme: 'black',
}

export default NavigationLink
