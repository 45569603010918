import dynamic from 'next/dynamic'
import React from 'react'

import type { PanelAddressProps } from '~/components/Panels/PanelAddress'
import type { PanelCartProps } from '~/components/Panels/PanelCart'
import type { PanelChangePasswordProps } from '~/components/Panels/PanelChangePassword'
import type { PanelContactProps } from '~/components/Panels/PanelContact'
import type { PanelEngravingProps } from '~/components/Panels/PanelEngraving'
import type { PanelFilteringProps } from '~/components/Panels/PanelFiltering'
import type { PanelForgotPasswordProps } from '~/components/Panels/PanelForgotPassword'
import type { PanelJournalFiltersProps } from '~/components/Panels/PanelJournalFilters'
import PanelLoading from '~/components/Panels/PanelLoading'
import type { PanelLoginProps } from '~/components/Panels/PanelLogin'
import PanelManager from '~/components/Panels/PanelManager'
import type { PanelOrderingProps } from '~/components/Panels/PanelOrdering'
import type { PanelReassuranceProps } from '~/components/Panels/PanelReassurance'
import type { PanelSizeGuideProps } from '~/components/Panels/PanelSizeGuide'
import PanelWrapper from '~/components/Panels/PanelWrapper'

const AddressPanel = dynamic(() => import('~/components/Panels/PanelAddress'), {
  ssr: false,
  loading: () => <PanelLoading />,
})
const ReassurancePanel = dynamic(
  () => import('~/components/Panels/PanelReassurance'),
  { ssr: false, loading: () => <PanelLoading /> },
)
const SizeGuidePanel = dynamic(
  () => import('~/components/Panels/PanelSizeGuide'),
  { ssr: false, loading: () => <PanelLoading /> },
)
const RegisterPanel = dynamic(
  () => import('~/components/Panels/PanelRegister'),
  { ssr: false, loading: () => <PanelLoading /> },
)
const LoginPanel = dynamic(() => import('~/components/Panels/PanelLogin'), {
  ssr: false,
  loading: () => <PanelLoading />,
})
const OrderingPanel = dynamic(
  () => import('~/components/Panels/PanelOrdering'),
  { ssr: false, loading: () => <PanelLoading /> },
)

const ContactPanel = dynamic(() => import('~/components/Panels/PanelContact'), {
  ssr: false,
  loading: () => <PanelLoading />,
})
const CartPanel = dynamic(() => import('~/components/Panels/PanelCart'), {
  ssr: false,
  loading: () => <PanelLoading />,
})

const CookiesPanel = dynamic(() => import('~/components/Panels/PanelCookies'), {
  ssr: false,
  loading: () => <PanelLoading />,
})

const EngravingPanel = dynamic(
  () => import('~/components/Panels/PanelEngraving'),
  { ssr: false, loading: () => <PanelLoading /> },
)

const FilteringPanel = dynamic(
  () => import('~/components/Panels/PanelFiltering'),
  { ssr: false, loading: () => <PanelLoading /> },
)

const ForgotPasswordPanel = dynamic(
  () => import('~/components/Panels/PanelForgotPassword'),
  { ssr: false, loading: () => <PanelLoading /> },
)
const ChangePasswordPanel = dynamic(
  () => import('~/components/Panels/PanelChangePassword'),
  { ssr: false, loading: () => <PanelLoading /> },
)
const JournalFiltersPanel = dynamic(
  () => import('~/components/Panels/PanelJournalFilters'),
  { ssr: false, loading: () => <PanelLoading /> },
)

export function PanelCookies() {
  return (
    <PanelWrapper>
      <CookiesPanel />
    </PanelWrapper>
  )
}

export function PanelAddress(props: PanelAddressProps) {
  return (
    <PanelWrapper>
      <AddressPanel {...props} />
    </PanelWrapper>
  )
}

export function PanelCart(props: PanelCartProps) {
  return (
    <PanelWrapper>
      <CartPanel {...props} />
    </PanelWrapper>
  )
}

export function PanelChangePassword(props: PanelChangePasswordProps) {
  return (
    <PanelWrapper>
      <ChangePasswordPanel {...props} />
    </PanelWrapper>
  )
}

export function PanelContact(props: PanelContactProps) {
  return (
    <PanelWrapper>
      <ContactPanel {...props} />
    </PanelWrapper>
  )
}

export function PanelEngraving(props: PanelEngravingProps) {
  return (
    <PanelWrapper>
      <EngravingPanel {...props} />
    </PanelWrapper>
  )
}

export function PanelFiltering(props: PanelFilteringProps) {
  return (
    <PanelWrapper withoutBorder>
      {({ hide }) => <FilteringPanel {...props} hide={hide} />}
    </PanelWrapper>
  )
}

export function PanelForgotPassword(props: PanelForgotPasswordProps) {
  return (
    <PanelWrapper>
      <ForgotPasswordPanel {...props} />
    </PanelWrapper>
  )
}

export function PanelLogin(props: PanelLoginProps) {
  return (
    <PanelWrapper>
      <LoginPanel {...props} />
    </PanelWrapper>
  )
}

export function PanelOrdering(props: PanelOrderingProps) {
  return (
    <PanelWrapper>
      {({ hide }) => <OrderingPanel {...props} hide={hide} />}
    </PanelWrapper>
  )
}

export function PanelReassurance(props) {
  return (
    <PanelWrapper>
      <ReassurancePanel {...props} />
    </PanelWrapper>
  )
}

export function PanelRegister(props: PanelReassuranceProps) {
  return (
    <PanelWrapper>
      <RegisterPanel {...props} />
    </PanelWrapper>
  )
}

export function PanelSizeGuide(props: PanelSizeGuideProps) {
  return (
    <PanelWrapper>
      <SizeGuidePanel {...props} />
    </PanelWrapper>
  )
}
export function PanelJournalFilters(props: PanelJournalFiltersProps) {
  return (
    <PanelWrapper withoutBorder verticalAlignCenter>
      <JournalFiltersPanel {...props} />
    </PanelWrapper>
  )
}

export default PanelManager
